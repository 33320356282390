//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useRef, useEffect, Fragment } from "react";
//* ---------------------------- third-party start ---------------------------- *//
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./DraftManagement.module.scss";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import { Overlay } from "react-bootstrap";
import Sortable from "sortablejs";
import {
  SustainReportDefaultTreeNodeModel,
  SustainReportDefaultTreeNodeRequestModel,
} from "models/admin/manuscriptModel";
import { CommonService } from "services/common/commonService";
import NormalInput from "components/base/NormalInput/NormalInput";
import { v4 as uuid } from "uuid";
import { success2Alert, error2Alert } from "utils/otherToast";
import ManuscriptService from "services/admin/manuscriptService";
import { usePermissionContext } from "context/PermissionProvider";
import { ReportManageActionsPermissionCodes } from "models/auth/permissionModel";
import Prompt from "../../../../components/otherModule/Prompt/Prompt";
//* ---------------------------- local end ---------------------------- *//

const DraftManagement: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  /** PermissionService */
  const { PermissionService } = usePermissionContext();
  /** detectEveryActionPermission */
  const { detectEveryActionPermission } = PermissionService;
  /** navigate */
  const navigate = useNavigate();
  /** params */
  const params = useParams();
  /** manageId */
  const manageId = params.id;
  /** addItemsTipShow */
  const [addItemsTipShow, setAddItemsTipShow] = useState(false);
  /** addItemsTipTarget */
  const addItemsTipTarget = useRef<HTMLButtonElement>(null);
  /** isDataChange */
  const [isDataChange, setIsDataChange] = useState(false);
  /** focusedItemKey */
  const [focusedItemKey, setFocusedItemKey] = useState<string | null>(null);
  /** sustainReportTree */
  const [sustainReportTree, setSustainReportTree] = useState<
    SustainReportDefaultTreeNodeModel[]
  >([]);
  /** originSustainReportTree */
  const [originSustainReportTree, setOriginSustainReportTree] = useState<
    SustainReportDefaultTreeNodeModel[]
  >([]);
  /** usedIds */
  const [usedIds, setUsedIds] = useState<Set<string>>(new Set());

  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  /** getDefaultSustainReportTree */
  const {
    mutate: getDefaultSustainReportTreeMutate,
    isPending: getDefaultSustainReportTreeIsPending,
  } = useMutation({
    mutationFn: (manageId: string) =>
      ManuscriptService.getDefaultSustainReportTree(manageId),
    onSuccess: (res) => {
      // console.log("getDefaultSustainReportTree", res);
      if (res.code === 200 && res.success) {
        const handleData: SustainReportDefaultTreeNodeModel[] =
          CommonService.deepClone(res.data) || [];
        setUsedIds(new Set());
        const collectIds = new Set<string>();
        const traverseIds = (items: SustainReportDefaultTreeNodeModel[]) => {
          items.forEach((item) => {
            collectIds.add(item.id);
            traverseIds(item.children);
          });
        };
        traverseIds(handleData);
        setUsedIds(collectIds);
        const refactorTreeForWebUse = (
          items: SustainReportDefaultTreeNodeModel[]
        ): SustainReportDefaultTreeNodeModel[] => {
          return items.map((item) => {
            return {
              ...new SustainReportDefaultTreeNodeModel(),
              ...item,
              children: refactorTreeForWebUse(item.children),
            };
          });
        };
        const initialData = preHandleBeforeSetData(
          refactorTreeForWebUse(handleData)
        );
        setSustainReportTree(initialData);
        setOriginSustainReportTree(initialData);
      } else {
        setSustainReportTree([]);
        error2Alert(res.message);
      }
      initPageState();
    },
    onError: (err) => {
      setSustainReportTree([]);
      initPageState();
      console.log("getDefaultSustainReportTree", err);
      error2Alert("執行失敗");
    },
  });

  //- editSustainReportTree
  const {
    mutate: editSustainReportTreeMutate,
    isPending: editSustainReportTreeIsPending,
  } = useMutation({
    mutationFn: (data: SustainReportDefaultTreeNodeRequestModel) =>
      ManuscriptService.editSustainReportTree(data),
    onSuccess: (res) => {
      console.log("editSustainReportTree", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        setUsedIds(new Set());
        getDefaultSustainReportTreeMutate(manageId!);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("editSustainReportTree", err);
      error2Alert("執行失敗");
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  /** generateUniqueId */
  const generateUniqueId = (): string => {
    let newId: string;
    do {
      newId = uuid();
    } while (usedIds.has(newId));
    setUsedIds((prev) => {
      const newSet = new Set(prev);
      newSet.add(newId);
      return newSet;
    });
    return newId;
  };

  /** handleAddItem */
  const handleAddItem = () => {
    if (!focusedItemKey) {
      error2Alert("請選擇要新增項目的父章節");
      return;
    }

    setSustainReportTree((prevData) => {
      const handleData = CommonService.deepClone(prevData);
      const newId = generateUniqueId();
      const newItem: SustainReportDefaultTreeNodeModel = {
        ...new SustainReportDefaultTreeNodeModel(),
        manageId: manageId!,
        title: "新章節",
        nodeId: newId,
        id: newId,
      };

      const addItemToParent = (items: SustainReportDefaultTreeNodeModel[]) => {
        items.forEach((item) => {
          if (item.uniqueKey === focusedItemKey) {
            item.children.push(newItem);
          } else {
            addItemToParent(item.children);
          }
        });
      };

      addItemToParent(handleData);
      return preHandleBeforeSetData(handleData);
    });
  };

  /** handleCollapsed */
  const handleCollapsed = (item: SustainReportDefaultTreeNodeModel): void => {
    setSustainReportTree((prevData) => {
      const handleData = CommonService.deepClone(prevData);
      const handleCollapsed = (
        handleData: SustainReportDefaultTreeNodeModel[],
        uniqueKey: string,
        isCollapsed: boolean
      ) => {
        handleData.forEach((item) => {
          if (item.uniqueKey === uniqueKey) {
            item.isCollapsed = isCollapsed;
          } else {
            handleCollapsed(item.children, uniqueKey, isCollapsed);
          }
        });
      };
      handleCollapsed(handleData, item.uniqueKey, !item.isCollapsed);
      return preHandleBeforeSetData(handleData);
    });
  };

  /** preHandleBeforeSetData */
  const preHandleBeforeSetData = (
    data: SustainReportDefaultTreeNodeModel[]
  ): SustainReportDefaultTreeNodeModel[] => {
    const preHandleItemPropertyData = preHandleItemProperty(data);
    return CommonService.deepClone(preHandleItemPropertyData);
  };

  /** preHandleItemData */
  const preHandleItemProperty = (
    data: SustainReportDefaultTreeNodeModel[]
  ): SustainReportDefaultTreeNodeModel[] => {
    const handleData = CommonService.deepClone(data);
    let parentChapterIndex = "";
    const handleItemDataProperty = (
      handleData: SustainReportDefaultTreeNodeModel[],
      parentChapterIndex: string = "",
      level: number = 0,
      parentId: string | null = null
    ) => {
      handleData.forEach((item, index) => {
        //- level
        item.level = level;
        //- sortIndex
        item.sortIndex = index;
        //- parentId
        item.parentId = level ? parentId : null;
        //- parentChapterIndex
        if (level === 1) {
          const chapterIndex = index > 8 ? index + 1 : "0" + (index + 1);
          item.webChapterIndex = parentChapterIndex + chapterIndex;
        } else {
          item.webChapterIndex = parentChapterIndex + (index + 1).toString();
        }
        handleItemDataProperty(
          item.children,
          item.webChapterIndex + ".",
          level + 1,
          item.nodeId
        );
      });
    };
    handleItemDataProperty(handleData, parentChapterIndex);
    return handleData;
  };

  /** preHandleInserBefore */
  const preHandleInserBefore = (evt: Sortable.SortableEvent) => {
    evt.item.remove();
    if (evt.oldIndex !== undefined) {
      evt.from.insertBefore(evt.item, evt.from.children[evt.oldIndex]);
    }
  };

  /** moveItem */
  const moveItem = (oldIndexPath: number[], finalNewPath: number[]) => {
    setSustainReportTree((prevData) => {
      try {
        let oldPathItem = prevData;
        let newPathItem = prevData;

        for (let i = 0; i < oldIndexPath.length - 1; i++) {
          oldPathItem = oldPathItem[oldIndexPath[i]].children;
        }
        for (let i = 0; i < finalNewPath.length - 1; i++) {
          newPathItem = newPathItem[finalNewPath[i]].children;
        }

        const oldControlIndex = oldIndexPath[oldIndexPath.length - 1];
        const newControlIndex = finalNewPath[finalNewPath.length - 1];
        const moveControl = oldPathItem.splice(oldControlIndex, 1)[0];

        newPathItem.splice(newControlIndex, 0, moveControl);

        return preHandleBeforeSetData([...prevData]);
      } catch (error) {
        console.error("移動元素錯誤:", error);
        return preHandleBeforeSetData(prevData);
      }
    });
  };

  /** removeItem */
  const removeItem = (item: SustainReportDefaultTreeNodeModel) => {
    setSustainReportTree((prevData) => {
      try {
        const idsToRemove = new Set<string>();
        const collectIdsToRemove = (
          node: SustainReportDefaultTreeNodeModel
        ) => {
          idsToRemove.add(node.id);
          node.children.forEach(collectIdsToRemove);
        };
        collectIdsToRemove(item);
        setUsedIds((prev) => {
          const newIds = new Set(prev);
          idsToRemove.forEach((id) => newIds.delete(id));
          return newIds;
        });
        const handleData = CommonService.deepClone(prevData);
        const removeItem = (
          items: SustainReportDefaultTreeNodeModel[],
          uniqueKey: string
        ) => {
          items.forEach((item, index) => {
            if (item.uniqueKey === uniqueKey) {
              items.splice(index, 1);
            } else {
              removeItem(item.children, uniqueKey);
            }
          });
        };
        removeItem(handleData, item.uniqueKey);
        setFocusedItemKey(null);
        return preHandleBeforeSetData(handleData);
      } catch (error) {
        console.error("刪除元素錯誤:", error);
        return preHandleBeforeSetData(prevData);
      }
    });
  };

  /** copyItem */
  const copyItem = (item: SustainReportDefaultTreeNodeModel) => {
    setSustainReportTree((prevData) => {
      try {
        const handleData = CommonService.deepClone(prevData);

        const generateNewIds = (
          item: SustainReportDefaultTreeNodeModel
        ): SustainReportDefaultTreeNodeModel => {
          const newId = generateUniqueId();
          return {
            ...item,
            nodeId: newId,
            id: newId,
            uniqueKey: uuid(),
            children: item.children.map(generateNewIds),
          };
        };

        const copyItem = (
          items: SustainReportDefaultTreeNodeModel[],
          uniqueKey: string
        ) => {
          items.forEach((item) => {
            if (item.uniqueKey === uniqueKey) {
              const newItem = generateNewIds(item);
              const index = items.findIndex((i) => i.uniqueKey === uniqueKey);
              items.splice(index + 1, 0, newItem);
            } else {
              copyItem(item.children, uniqueKey);
            }
          });
        };

        copyItem(handleData, item.uniqueKey);
        return preHandleBeforeSetData(handleData);
      } catch (error) {
        console.error("複製元素錯誤:", error);
        return preHandleBeforeSetData(prevData);
      }
    });
  };

  /** isDataEdited */
  const isDataEdited = (
    newData: SustainReportDefaultTreeNodeModel[],
    originalData: SustainReportDefaultTreeNodeModel[]
  ): boolean => {
    const deepCloneWithoutSpecificProperty = (
      data: SustainReportDefaultTreeNodeModel[]
    ): SustainReportDefaultTreeNodeModel[] => {
      return CommonService.deepClone(data).map((item) => {
        return {
          ...item,
          isCollapsed: false,
          valid: true,
          children: deepCloneWithoutSpecificProperty(item.children),
        };
      });
    };
    const newDataWithoutSpecificProperty =
      deepCloneWithoutSpecificProperty(newData);
    const originalDataWithoutSpecificProperty =
      deepCloneWithoutSpecificProperty(originalData);
    return (
      JSON.stringify(newDataWithoutSpecificProperty) !==
      JSON.stringify(originalDataWithoutSpecificProperty)
    );
  };

  /** handleTitleChange */
  const handleTitleChange = (uniqueKey: string, newTitle: string) => {
    // console.log("handleTitleChange", uniqueKey, newTitle);
    setSustainReportTree((prevData) => {
      const handleData = CommonService.deepClone(prevData);

      const updateTitle = (items: SustainReportDefaultTreeNodeModel[]) => {
        items.forEach((item) => {
          if (item.uniqueKey === uniqueKey) {
            item.title = newTitle;
          } else {
            updateTitle(item.children);
          }
        });
      };

      updateTitle(handleData);
      const newData = preHandleBeforeSetData(handleData);
      setIsDataChange(isDataEdited(newData, originSustainReportTree));
      return newData;
    });
  };

  /** saveSustainReportTree */
  const saveSustainReportTree = () => {
    if (!manageId || !isDataChange) return;
    if (!validateSaveData()) return;
    editSustainReportTreeMutate({
      manageId: manageId!,
      SustainReportDefaultTreeNodes: sustainReportTree,
    });
  };

  /** validateSaveData */
  const validateSaveData = () => {
    //- state
    let valid = true;
    let invalidMessage = "";

    //- function
    //> updateInvalidMessage
    const updateInvalidMessage = (message: string) => {
      if (!invalidMessage) {
        valid = false;
        invalidMessage = message;
      }
    };

    //> validateChapterTitle
    const validateChapterTitle = (
      chapterItem: SustainReportDefaultTreeNodeModel
    ): boolean => {
      if (
        chapterItem.title === "" ||
        chapterItem.title === null ||
        chapterItem.title === undefined
      ) {
        chapterItem.checkValid = false;
        updateInvalidMessage("請填寫章節名稱");
        return false;
      } else {
        chapterItem.checkValid = true;
        return true;
      }
    };

    //> checkEveryChapter
    const checkEveryChapter = (
      items: SustainReportDefaultTreeNodeModel[]
    ): SustainReportDefaultTreeNodeModel[] => {
      return items.map((item) => {
        validateChapterTitle(item);
        return {
          ...item,
          children: checkEveryChapter(item.children),
        };
      });
    };

    //> validateIds
    const validateIds = () => {
      const idSet = new Set<string>();
      const duplicateIds = new Set<string>();
      const checkDuplicateIds = (
        items: SustainReportDefaultTreeNodeModel[]
      ) => {
        let isValid = true;
        items.forEach((item) => {
          if (idSet.has(item.id)) {
            isValid = false;
            duplicateIds.add(item.id);
            updateInvalidMessage(
              `系統發生異常，請重新整理頁面後再試。如問題持續發生，請聯繫系統管理員。`
            );
          }
          idSet.add(item.id);
          if (!checkDuplicateIds(item.children)) {
            isValid = false;
          }
        });
        return isValid;
      };

      const result = checkDuplicateIds(checkData);

      // 若有重複 ID 則輸出到 console
      if (duplicateIds.size > 0) {
        console.log("發現重複的 ID:", {
          duplicateIds: Array.from(duplicateIds),
          allIds: Array.from(idSet),
        });
      }

      return result;
    };

    //- validate
    const checkData = CommonService.deepClone(sustainReportTree);

    checkEveryChapter(checkData);

    if (!validateIds()) {
      setSustainReportTree(checkData);
      valid = false;
    }

    setSustainReportTree(checkData);

    // alert invalidMessage
    if (!valid && invalidMessage) {
      error2Alert(invalidMessage);
      setTimeout(() => {
        const invalidNode = document.querySelector(".invalid");
        if (invalidNode) {
          //> 滑到置中
          invalidNode.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    }

    return valid;
  };

  /** initPageState */
  const initPageState = () => {
    setFocusedItemKey(null);
    setAddItemsTipShow(false);
    setIsDataChange(false);
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//

  /** renderList */
  const renderList = (
    data: SustainReportDefaultTreeNodeModel[],
    level: number = 0,
    path: number[] = [],
    parent: SustainReportDefaultTreeNodeModel | null = null,
    previousCollapsed: boolean = false
  ): JSX.Element => {
    // console.log("Rendering list with data:", data);
    let renderCollapsed = parent?.isCollapsed || previousCollapsed || false;
    return renderCollapsed ? (
      <>
        {data.length ? (
          <>
            {data.map((item, index) => {
              const currentPath = path.concat(index);
              item.path = currentPath;
              return (
                <Fragment key={item.uniqueKey}>
                  {renderList(
                    item.children,
                    level + 1,
                    currentPath,
                    item,
                    item.isCollapsed || renderCollapsed
                  )}
                </Fragment>
              );
            })}
          </>
        ) : null}
      </>
    ) : (
      <div
        className={`handle-sort-list-container`}
        style={{ paddingLeft: level ? 54 : 2 }}
      >
        {data.length ? (
          <>
            {data.map((item, index) => {
              const currentPath = path.concat(index);
              item.path = currentPath;
              return (
                <div
                  key={item.uniqueKey}
                  data-path={currentPath.join(",")}
                  className={`sortItem`}
                  tabIndex={0}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setFocusedItemKey(item.uniqueKey);
                  }}
                  onMouseUp={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    className={`itemBox ${
                      focusedItemKey === item.uniqueKey ? "focused" : ""
                    }`}
                  >
                    <div className="sortBtn glyphicon-move">
                      <img
                        alt="sort"
                        src="/assets/images/buttonIcon/hambur-icon.svg"
                      />
                    </div>
                    {item.children.length ? (
                      <div
                        className="collapsedBtn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCollapsed(item);
                        }}
                      >
                        <img
                          alt="arrow"
                          src={`/assets/images/buttonIcon/${
                            item.isCollapsed
                              ? "right-arrow.svg"
                              : "down-arrow-icon.svg"
                          }`}
                        />
                      </div>
                    ) : null}
                    <div
                      className={`chapterIndex ${
                        !item.children.length ? "noChild" : ""
                      }`}
                    >
                      {item.webChapterIndex}
                    </div>
                    <div className={`name`}>
                      <NormalInput
                        isFloatTitle={false}
                        defaultValue={item.title}
                        valid={item.checkValid}
                        onChange={(e) => handleTitleChange(item.uniqueKey, e)}
                      />
                    </div>
                    {rederItemOperatorBox(item)}
                  </div>
                  {renderList(
                    item.children,
                    level + 1,
                    currentPath,
                    item,
                    item.isCollapsed || renderCollapsed
                  )}
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    );
  };

  /** rederItemOperatorBox */
  const rederItemOperatorBox = (item: SustainReportDefaultTreeNodeModel) => {
    const isOnlyLevel0Item = item.level === 0 && sustainReportTree.length === 1;
    return (
      <div className="operatorBox">
        <img
          alt="edit"
          src="/assets/images/buttonIcon/copy-icon.svg"
          onClick={(e) => {
            e.stopPropagation();
            copyItem(item);
          }}
        />
        <img
          alt="edit"
          src="/assets/images/buttonIcon/trash-icon.svg"
          className={`${isOnlyLevel0Item ? "disabled" : ""}`}
          onClick={(e) => {
            if (isOnlyLevel0Item) return;
            e.stopPropagation();
            removeItem(item);
          }}
        />
        <img
          alt="edit"
          src="/assets/images/buttonIcon/edit-icon.svg"
          className={`${item.level ? "" : "disabled"}`}
          onClick={(e) => {
            if (!item.level) return;
            e.stopPropagation();
            navigate(`${item.nodeId}/settings`);
          }}
        />
      </div>
    );
  };
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- useEffect sustainReportTree, sortable
  useEffect(() => {
    // console.log("sustainReportTree", sustainReportTree);
    try {
      const listContainers = document.querySelectorAll(
        `.handle-sort-list-container`
      );
      listContainers.forEach((container) => {
        new Sortable(container as HTMLElement, {
          group: {
            name: "list",
            pull: true,
            put: true,
          },
          handle: ".glyphicon-move",
          animation: 150,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          invertSwap: true,
          ghostClass: "sort-ghost-background-class",
          direction: "vertical",
          onEnd: (evt: Sortable.SortableEvent) => {
            try {
              // console.log(evt.oldIndex, evt.newIndex);
              const oldIndexPath = evt.item
                .getAttribute("data-path")!
                .split(",")
                .map(Number);
              const newIndexFromNewContainer = Array.from(
                evt.to.children
              ).indexOf(evt.item);

              const parentSortItem = evt.to.closest(".sortItem");
              const newContainerPath =
                parentSortItem
                  ?.getAttribute("data-path")
                  ?.split(",")
                  .map(Number) || [];
              const finalNewPath = newContainerPath.concat(
                newIndexFromNewContainer
              );
              preHandleInserBefore(evt);
              moveItem(oldIndexPath, finalNewPath);
            } catch (error) {
              console.error("拖拽結束錯誤:", error);
            }
          },
        });
      });
    } catch (error) {
      console.error("初始化 Sortable.js 錯誤:", error);
    }
    //- odd,even class
    const listItems = document.querySelectorAll(".sortItem");
    listItems.forEach((item, index) => {
      item.classList.remove("odd", "even");
      if (index % 2 === 0) {
        item.classList.add("even");
      } else {
        item.classList.add("odd");
      }
    });
    console.log("sustainReportTree", sustainReportTree);
  }, [sustainReportTree]);

  //- useEffect init
  useEffect(() => {
    // console.log(manageId);
    if (!manageId) return;
    getDefaultSustainReportTreeMutate(manageId);
    return () => {
      setUsedIds(new Set());
    };
  }, []);

  //- useEffect isDataChange
  useEffect(() => {
    // console.log(
    //   "isDataChange",
    //   isDataEdited(sustainReportTree, originSustainReportTree)
    // );
    setIsDataChange(isDataEdited(sustainReportTree, originSustainReportTree));
  }, [sustainReportTree, originSustainReportTree]);

  //- useEffect focusedItemKey
  useEffect(() => {
    // console.log("focusedItemKey", focusedItemKey);
    // setAddItemsTipShow(focusedItemKey ? false : true);
  }, [focusedItemKey]);

  //- useEffect mousedown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !document
          .getElementById("draft-management-container")
          ?.contains(event.target as Node)
      ) {
        setFocusedItemKey(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //- useEffect documnet body overflow-y hidden
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "";
    };
  }, []);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <div id="draft-management-container" className={`${styles["list-box"]}`}>
      <Prompt
        when={isDataChange}
        message="您有未保存的變更，確定要離開這個頁面嗎？"
      />
      {getDefaultSustainReportTreeIsPending ||
      editSustainReportTreeIsPending ? (
        <VisuallLoading />
      ) : null}
      <div className="list-title-box">
        <div className="left">章節與底稿管理</div>
        <div className="right">
          {detectEveryActionPermission([
            ReportManageActionsPermissionCodes.ReportCnUpdate,
          ]) ? (
            <button
              className={`secondary saveBtn mr-1-m
              ${!isDataChange ? "customDisabled" : ""}
            `}
              onClick={saveSustainReportTree}
            >
              儲存
            </button>
          ) : null}
          {detectEveryActionPermission([
            ReportManageActionsPermissionCodes.ReportCnUpdate,
          ]) ? (
            <>
              <button
                className={`default addBtn ${
                  !focusedItemKey ? "customDisabled" : ""
                }`}
                ref={addItemsTipTarget}
                onMouseEnter={() => {
                  if (focusedItemKey) return;
                  setAddItemsTipShow(true);
                }}
                onMouseLeave={() => {
                  setAddItemsTipShow(false);
                }}
                onClick={handleAddItem}
              >
                +新增項目
              </button>
              <Overlay
                placement={"bottom-end"}
                show={addItemsTipShow}
                target={addItemsTipTarget.current}
              >
                {({
                  placement: _placement,
                  arrowProps: _arrowProps,
                  show: _show,
                  popper: _popper,
                  hasDoneInitialMeasure: _hasDoneInitialMeasure,
                  ...props
                }) => {
                  const { style, ref, ...rest } = props;
                  return (
                    <div
                      ref={ref}
                      style={style}
                      className={styles["tip-box"]}
                      {...rest}
                    >
                      點選要新增的章節列才可新增
                    </div>
                  );
                }}
              </Overlay>
            </>
          ) : null}
        </div>
      </div>
      <div
        className={`${styles["list-table-box"]} list-table-scroll-box scroll`}
      >
        <ScrollToTopButton
          targetClassName={"list-table-scroll-box"}
          bottom={90}
          right={35}
        />

        <div className="sortable-head">
          <div></div>
          <div>章節</div>
          <div>功能</div>
        </div>
        <div className={`sortable-list`}>
          {sustainReportTree.length ? (
            renderList(sustainReportTree)
          ) : (
            <div className="no-data">查無資料</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DraftManagement;
