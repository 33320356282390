import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./ReportGenerationInfo.module.scss";
import BackTitle from "components/base/BackTitle/BackTitle";
import { PageActionTyep } from "models/baseModel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import RGstep1 from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep1/RGstep1";
import RGstep2 from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep2/RGstep2";
import RGstep3 from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep3/RGstep3";
import RGstep4 from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep4/RGstep4";
import SustainReportProduceService from "services/application/sustainReportDeclare/sustainReportProduce";
import { ResponseCode } from "models/responseCodeModel";
import { useMutation } from "@tanstack/react-query";
import {
  ReportProduceAction,
  ReportProduceStatus,
  StepItemModel,
  SustainReportProduceInfoModel,
  SustainReportProduceStatusModel,
} from "models/application/sustainReportDeclare/sustainReportProduceModel";
import { error2Alert } from "utils/otherToast";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";

interface ReportGenerationInfoProps { }

const ReportGenerationInfo: FC<ReportGenerationInfoProps> = () => {
  /** 路由對應參數 */
  const params = useParams();
  /** 路由 */
  const navigate = useNavigate();
  /** 路由變化 */
  const location = useLocation();
  /** 基本狀態資訊 */
  const [infoData, setInfoData] = useState<SustainReportProduceInfoModel>({
    produceId: "",
    year: 0,
    produceStatus: ReportProduceStatus.NotStarted,
    stepStatus: ReportProduceAction.StartProduce,
    produceStatusName: "",
  });
  /** 縮合 */
  const [showHeader, setShowHeader] = useState<boolean>(true);
  /** 第二步驟Component */
  const step2Child = useRef<any>();

  const [stepItems, setStepItems] = useState<StepItemModel[]>([
    {
      key: "step1",
      sno: 1,
      name: "選擇章節",
      status: ReportProduceAction.SelectChapter,
    },
    {
      key: "step2",
      sno: 2,
      name: "製作報告書",
      status: ReportProduceAction.ReportGenerate,
    },
    {
      key: "step3",
      sno: 3,
      name: "選擇樣版",
      status: ReportProduceAction.SelectStyle,
    },
    {
      key: "step4",
      sno: 4,
      name: "匯出永續報告書",
      status: ReportProduceAction.ExportReport,
    },
  ]);

  useEffect(() => {
    if (params.id) {
      getStatus.mutate(params.id);
    }
  }, []);

  //#region 方法區塊

  /** 回清單頁 */
  const handleBackList = () => {
    if (params.stepId === "step2") {
      // 第二階段需要確認資料有無更改
      if (step2Child && step2Child.current) {
        step2Child.current.openLeaveConfirm(() => {
          navigate("/apply/report-generation/list");
        });
      }
    } else {
      navigate("/apply/report-generation/list");
    }
  };
  //#endregion

  //#region step item

  const changeStepFlow = (item: StepItemModel) => {
    // 大於狀態不能跳至下一階段
    if (item.status > infoData.stepStatus) {
      // error2Alert("資料填寫完成後，請按下一步");
    } else {
      changeStepItem(item.sno, item.status);
    }
  };
  type StepItemComponentModel = {
    item: StepItemModel;
    className?: string;
  };

  const StepItem: FC<StepItemComponentModel> = ({
    className = "",
    ...props
  }) => {
    const { item } = props;
    return (
      <div
        className={`step-item-box ` + `${className} `}
        onClick={() => {
          changeStepFlow(item);
        }}
      >
        <div className="step-sno">
          <div className="sno">{item.sno}</div>
        </div>
        <div className="step-name "> {item.name}</div>
      </div>
    );
  };

  const StepLine: FC<{ isActive: boolean }> = (props) => {
    return (
      <div
        className={`step-line ` + `${props.isActive ? "active " : ""} `}
      ></div>
    );
  };
  //#endregion

  //#region 方法區塊
  /** 切換step */
  const changeStepItem = (step: number, status: ReportProduceAction) => {
    statusFlow(step, status);
  };

  /** 切換step */
  const changeShowHeader = (show: boolean) => {
    setShowHeader(show);
  };

  /** 取得子元件 */
  const getChildComponent = () => {
    // console.log(params.stepId);
    switch (params.stepId) {
      case "step1":
        return (
          <RGstep1
            gotoStep={changeStepItem}
            changeShowHeader={changeShowHeader}
            showHeader={showHeader}
          />
        );
      case "step2":
        return (
          <RGstep2
            onRef={step2Child}
            gotoStep={changeStepItem}
            changeShowHeader={changeShowHeader}
            showHeader={showHeader}
            year={infoData?.year}
          />
        );
      case "step3":
        return (
          <RGstep3
            gotoStep={changeStepItem}
            changeShowHeader={changeShowHeader}
            showHeader={showHeader}
          />
        );
      case "step4":
        return <RGstep4 />;
    }
  };

  /** 更改步驟流程 */
  const statusFlow = (step: number, status: ReportProduceAction) => {
    editStatus.mutate(
      {
        produceId: params.id!,
        action: status,
      },
      {
        onSuccess: (res) => {
          if (res.success && res.code === ResponseCode.SuccessOK) {
            getStatus.mutate(params.id!);
            navigate(`/apply/report-generation/${params.id}/info/step${step}`);
          } else {
            console.log(res);
            // error2Alert(res.message);
          }
        },
      }
    );
  };
  //#endregion

  //#region API區塊
  /** 永續報告書狀態 */
  const getStatus = useMutation({
    mutationFn: (procedureId: string) =>
      SustainReportProduceService.getStatus(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportProduceInfoModel = res.data;
        setInfoData({ ...data });
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 編輯狀態 */
  const editStatus = useMutation({
    mutationFn: (model: SustainReportProduceStatusModel) =>
      SustainReportProduceService.editStatus(model),
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  return (
    <div className="no-nav-content-box">
      {(editStatus.isPending || getStatus.isPending) && <VisuallLoading />}
      <div className={styles["report-generation-info-box"]}>
        <div className={`title-box ` + `${showHeader ? "show" : "hide"} `}>
          <BackTitle
            url=""
            title={`${infoData?.year}年`}
            handleOtherEvent={true}
            changeItem={handleBackList}
          />
          <div className="step-box">
            {stepItems.map((item, index: number) => {
              return (
                <div key={item.key} className="step-item">
                  {index !== 0 && (
                    <StepLine isActive={params.stepId! >= item.key} />
                  )}
                  <StepItem
                    className={
                      `${params.stepId === item.key ? "active " : ""}` +
                      `${infoData?.stepStatus > item.status ? "filled " : ""}`
                    }
                    item={item}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {/* 切換對應compoenet */}
        <div className={`report-step-info-box ` +
          `${showHeader ? "" : "full-h"} `
        }>{getChildComponent()}</div>
      </div>
    </div>
  );
};

export default ReportGenerationInfo;
