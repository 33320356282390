//* ---------------------------- React start ---------------------------- *//
import {
  FC,
  useState,
  useRef,
  useEffect,
  useContext,
  memo,
  useMemo,
} from "react";
//* ---------------------------- third-party start ---------------------------- *//
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./DraftSettings.module.scss";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import {
  SustainReportManuscriptDefaultDataModel,
  SustainReportGriStandardModel,
  SustainReportManuscriptDefaultTableModel,
  SustainReportManuscriptDefaultCellModel,
  SustainReportDefaultFlatTreeNodeModel,
} from "models/admin/manuscriptModel";
import { CommonService } from "services/common/commonService";
import NormalInput from "components/base/NormalInput/NormalInput";
import { v4 as uuid } from "uuid";
import { success2Alert, error2Alert } from "utils/otherToast";
import ManuscriptService from "services/admin/manuscriptService";
import MultiSelect from "components/base/MultiSelect/MultiSelect";
import { MultiOptionModel } from "models/baseModel";
import NormalTextarea from "components/base/NormalTextarea/NormalTextarea";
import { jsEditor } from "utils/timyMceEditor/jsEditor";
import { jsEditorOptionModel } from "utils/timyMceEditor/jsEditorOptiosn";
import { LayoutContext } from "./DraftSettingsLayout/DraftSettingsLayout";
import { usePermissionContext } from "context/PermissionProvider";
import { ReportManageActionsPermissionCodes } from "models/auth/permissionModel";
//* ---------------------------- local end ---------------------------- *//

const DraftSettings: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  /** PermissionService */
  const { PermissionService } = usePermissionContext();
  /** detectEveryActionPermission */
  const { detectEveryActionPermission } = PermissionService;
  /** layoutData */
  const {
    setLayoutData,
    sustainReportFlatTree,
    selectedSustainReportFlatTreeItem,
    childOutletIsDataChange,
    getDefaultSustainReportFlatTreeMutate,
  } = useContext(LayoutContext);
  /** selectedSustainReportFlatTreeItemRef */
  const selectedSustainReportFlatTreeItemRef =
    useRef<SustainReportDefaultFlatTreeNodeModel | null>(null);
  /** params */
  const params = useParams();
  /** manageId */
  const manageId = params.id;
  /** nodeId */
  const nodeId = params.nodeId;
  /** ongoingManageId */
  const [ongoingManageId, setOngoingManageId] = useState<string>();
  /** ongoingNodeId */
  const [ongoingNodeId, setOngoingNodeId] = useState<string>();
  /** sustainReportChapterData */
  const [sustainReportChapterData, setSustainReportChapterData] =
    useState<SustainReportManuscriptDefaultDataModel>(
      new SustainReportManuscriptDefaultDataModel()
    );
  /** originSustainReportChapterData */
  const [originSustainReportChapterData, setOriginSustainReportChapterData] =
    useState<SustainReportManuscriptDefaultDataModel>(
      new SustainReportManuscriptDefaultDataModel()
    );
  /** griStandardOptionList */
  const [griStandardOptionList, setGriStandardOptionList] = useState<
    MultiOptionModel[]
  >([]);
  /** GRI_editor */
  const GRI_editor = useRef<jsEditor>();
  /** reference_editor */
  const reference_editor = useRef<jsEditor>();
  /** isEditorInitialLoad */
  const isEditorInitialLoad = useRef<boolean>(true);

  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  /** getDefaultChapterData */
  const {
    mutate: getDefaultChapterDataMutate,
    isPending: getDefaultChapterDataIsPending,
  } = useMutation({
    mutationFn: (request: {
      defaultDataId: string | null;
      defaultId: string;
    }) =>
      ManuscriptService.getDefaultChapterData(
        request.defaultDataId,
        request.defaultId
      ),
    onSuccess: (res) => {
      // console.log("getDefaultChapterData", res);
      if (res.code === 200 && res.success) {
        const cleanedData = Object.keys(res.data).reduce(
          (acc: { [key: string]: any }, key: string) => {
            if (res.data[key] !== null && res.data[key] !== undefined) {
              acc[key] = res.data[key];
            }
            return acc;
          },
          {}
        );
        if (cleanedData.dataId) {
          selectedSustainReportFlatTreeItemRef.current!.defaultDataId =
            cleanedData.dataId;
        }
        if (cleanedData.griStandards) {
          cleanedData.griOptionsVal = griStandardOptionList.map((option) => {
            return {
              id: option.id,
              text: option.text,
              checked: cleanedData.griStandards.includes(option.id),
            };
          });
        }
        if (cleanedData.tableList?.length) {
          cleanedData.tableIdList = cleanedData.tableList.map(
            (t: SustainReportManuscriptDefaultTableModel) => t.tableId
          );
        }
        const newSustainReportChapterData = {
          ...new SustainReportManuscriptDefaultDataModel(),
          ...cleanedData,
        };
        console.log("newSustainReportChapterData", newSustainReportChapterData);
        setSustainReportChapterData(newSustainReportChapterData);
        setOriginSustainReportChapterData(newSustainReportChapterData);
        // console.log("getDefaultChapterData", {
        //   ...new SustainReportManuscriptDefaultDataModel(),
        //   ...cleanedData,
        // });
      } else {
        setSustainReportChapterData(
          new SustainReportManuscriptDefaultDataModel()
        );
        setOriginSustainReportChapterData(
          new SustainReportManuscriptDefaultDataModel()
        );
        error2Alert(res.message);
      }
      // initPageState();
    },
    onError: (err) => {
      setSustainReportChapterData(
        new SustainReportManuscriptDefaultDataModel()
      );
      setOriginSustainReportChapterData(
        new SustainReportManuscriptDefaultDataModel()
      );
      // initPageState();
      console.log("getDefaultChapterData", err);
      error2Alert("執行失敗");
    },
  });

  /** editDefaultChapterData */
  const {
    mutate: editDefaultChapterDataMutate,
    isPending: editDefaultChapterDataIsPending,
  } = useMutation({
    mutationFn: (request: SustainReportManuscriptDefaultDataModel) =>
      ManuscriptService.editDefaultChapterData(request),
    onSuccess: (res) => {
      // console.log("editDefaultChapterData", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        getDefaultChapterDataMutate(
          {
            defaultDataId:
              selectedSustainReportFlatTreeItemRef.current!.defaultDataId ||
              null,
            defaultId: selectedSustainReportFlatTreeItemRef.current!.defaultId,
          },
          {
            onSuccess: (secRes) => {
              if (secRes.code === 200 && secRes.success) {
                //- 更改 layoutData chapter node title
                const resData: SustainReportManuscriptDefaultDataModel =
                  secRes.data;
                const findNode = sustainReportFlatTree.find(
                  (e) =>
                    e.defaultId ===
                    selectedSustainReportFlatTreeItemRef.current!.defaultId
                );
                if (
                  findNode &&
                  resData.title !== findNode.title &&
                  getDefaultSustainReportFlatTreeMutate &&
                  manageId
                ) {
                  getDefaultSustainReportFlatTreeMutate(manageId);
                }
              }
            },
          }
        );
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("editDefaultChapterData", err);
      error2Alert("執行失敗");
    },
  });

  /** getGriStandard */
  const { mutate: getGriStandardMutate, isPending: getGriStandardIsPending } =
    useMutation({
      mutationFn: () => ManuscriptService.getGriStandard(),
      onSuccess: (res) => {
        // console.log("getGriStandard", res);
        if (res.code === 200 && res.success) {
          setGriStandardOptionList(
            res.data.map((option: SustainReportGriStandardModel) => {
              return {
                id: option.griSno,
                text: option.griStandard,
              };
            })
          );
          getDefaultChapterDataMutate({
            defaultDataId:
              selectedSustainReportFlatTreeItemRef.current!.defaultDataId ||
              null,
            defaultId: selectedSustainReportFlatTreeItemRef.current!.defaultId,
          });
        } else {
          setGriStandardOptionList([]);
          error2Alert(res.message);
        }
        // initPageState();
      },
      onError: (err) => {
        setGriStandardOptionList([]);
        console.log("getGriStandard", err);
        error2Alert("執行失敗");
      },
    });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//

  /** generateNewTable */
  const generateNewTable = () => {
    const newTable = new SustainReportManuscriptDefaultTableModel();
    newTable.tableId = uuid();
    newTable.tableName = "新表格";
    newTable.rowSize = 0;
    newTable.columnSize = 0;

    const cells: SustainReportManuscriptDefaultCellModel[] = [];

    if (newTable.rowSize === 0 || newTable.columnSize === 0) {
      // 如果行列數為 0，則至少生成一個 cell
      const newCell = new SustainReportManuscriptDefaultCellModel();
      newCell.tableId = newTable.tableId;
      newCell.cellId = uuid();
      newCell.rowIndex = 0;
      newCell.columnIndex = 0;
      newCell.cellValue = "";
      cells.push(newCell);
    } else {
      for (let i = 0; i < newTable.rowSize; i++) {
        for (let j = 0; j < newTable.columnSize; j++) {
          const existingCell = newTable?.cells.find(
            (cell) => cell.rowIndex === i && cell.columnIndex === j
          );
          if (existingCell) {
            cells.push(existingCell);
          } else {
            const newCell = new SustainReportManuscriptDefaultCellModel();
            newCell.tableId = newTable.tableId;
            newCell.cellId = uuid();
            newCell.rowIndex = i;
            newCell.columnIndex = j;
            newCell.cellValue = "";
            cells.push(newCell);
          }
        }
      }
    }

    newTable.cells = cells;
    // setTableData(newTable);
    setSustainReportChapterData((prev) => {
      const updatedTableList = [...(prev.tableList || []), newTable];
      return {
        ...prev,
        tableList: updatedTableList,
        tableIdList: [...(prev.tableIdList || []), newTable.tableId],
      };
    });
  };

  /** updateTableCells */
  const updateTableCells = (
    data: SustainReportManuscriptDefaultDataModel
  ): SustainReportManuscriptDefaultDataModel => {
    const handleData = CommonService.deepClone(data);
    const updatedTableList = handleData.tableList.map((table) => {
      const newCells: SustainReportManuscriptDefaultCellModel[] = [];

      for (let i = 0; i < table.rowSize; i++) {
        for (let j = 0; j < table.columnSize; j++) {
          const existingCell = table.cells.find(
            (cell) => cell.rowIndex === i && cell.columnIndex === j
          );
          if (existingCell) {
            newCells.push({ ...existingCell, rowIndex: i, columnIndex: j });
          } else {
            const newCell = new SustainReportManuscriptDefaultCellModel();
            newCell.tableId = table.tableId;
            newCell.cellId = uuid();
            newCell.rowIndex = i;
            newCell.columnIndex = j;
            newCell.cellValue = "";
            newCells.push(newCell);
          }
        }
      }

      return {
        ...table,
        cells: newCells,
      };
    });

    handleData.tableList = updatedTableList;
    handleData.tableIdList = updatedTableList.map((t) => t.tableId);
    return handleData;
  };

  /** isDataEdited */
  const isDataEdited = (
    newData: SustainReportManuscriptDefaultDataModel,
    originalData: SustainReportManuscriptDefaultDataModel
  ): boolean => {
    const deepCloneWithoutSpecificProperty = (
      data: SustainReportManuscriptDefaultDataModel
    ): SustainReportManuscriptDefaultDataModel => {
      const cloneData = CommonService.deepClone(data);
      cloneData.titleCheckValid = true;
      cloneData.griOptionsVal = [];
      if (cloneData.tableList?.length) {
        cloneData.tableList.forEach(
          (table: SustainReportManuscriptDefaultTableModel) => {
            table.tableNameCheckValid = true;
          }
        );
      }
      return cloneData;
    };
    const newDataWithoutSpecificProperty =
      deepCloneWithoutSpecificProperty(newData);
    const originalDataWithoutSpecificProperty =
      deepCloneWithoutSpecificProperty(originalData);

    const deepEqual = (obj1: any, obj2: any): boolean => {
      if (obj1 === obj2) return true;

      if (
        typeof obj1 !== "object" ||
        typeof obj2 !== "object" ||
        obj1 === null ||
        obj2 === null
      ) {
        return false;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    };

    console.log(
      "isDataEdited",
      !deepEqual(
        newDataWithoutSpecificProperty,
        originalDataWithoutSpecificProperty
      ),
      newDataWithoutSpecificProperty,
      originalDataWithoutSpecificProperty
    );
    return !deepEqual(
      newDataWithoutSpecificProperty,
      originalDataWithoutSpecificProperty
    );
  };

  /** validateSaveData */
  const validateSaveData = () => {
    //- state
    // console.log('sustainReportChapterData', sustainReportChapterData);
    let valid = true;
    let invalidMessage = "";

    //- function
    //> updateInvalidMessage
    const updateInvalidMessage = (message: string) => {
      if (!invalidMessage) {
        valid = false;
        invalidMessage = message;
      }
    };

    //> validateChapterTitle
    const validateChapterTitle = (
      chapterData: SustainReportManuscriptDefaultDataModel
    ): boolean => {
      if (
        chapterData.title === "" ||
        chapterData.title === null ||
        chapterData.title === undefined
      ) {
        chapterData[`titleCheckValid`] = false;
        updateInvalidMessage("請填寫章節名稱");
        return false;
      } else {
        chapterData[`titleCheckValid`] = true;
        return true;
      }
    };

    //> validTable
    const validTable = (
      chapterData: SustainReportManuscriptDefaultDataModel
    ): boolean => {
      //>> validTableHasCellValueThatIsEmptyTableName
      const validTableHasCellValueThatIsEmptyTableName = (
        handleChapterData: SustainReportManuscriptDefaultDataModel
      ): boolean => {
        const tableCellsHaveAnyValueThatIsEmptyTableName =
          handleChapterData.tableList.some((table) => {
            const tableName = table.tableName;
            if (
              tableName !== "" &&
              tableName !== null &&
              tableName !== undefined
            ) {
              table.tableNameCheckValid = true;
              return false;
            }
            const tableCellsHaveAnyValue = table.cells.some(
              (cell) =>
                cell.cellValue !== "" &&
                cell.cellValue !== null &&
                cell.cellValue !== undefined
            );
            if (tableCellsHaveAnyValue) {
              table.tableNameCheckValid = false;
              return true;
            } else {
              table.tableNameCheckValid = true;
              return false;
            }
          });
        if (tableCellsHaveAnyValueThatIsEmptyTableName) {
          updateInvalidMessage("表格名稱不可為空");
          return false;
        }
        return true;
      };

      //>> validDuplicateTableNames
      const validDuplicateTableNames = (
        handleChapterData: SustainReportManuscriptDefaultDataModel
      ): boolean => {
        const tableNames = handleChapterData.tableList
          .map((table) => table.tableName)
          .filter((name) => name !== "" && name !== null && name !== undefined);
        const uniqueTableNames = Array.from(new Set(tableNames));
        if (tableNames?.length !== uniqueTableNames?.length) {
          handleChapterData.tableList.forEach((table) => {
            if (uniqueTableNames.includes(table.tableName)) {
              table.tableNameCheckValid = false;
            } else {
              table.tableNameCheckValid = true;
            }
          });
          updateInvalidMessage("表格名稱不可重複");
          return false;
        }
        return true;
      };

      if (!validTableHasCellValueThatIsEmptyTableName(chapterData))
        return false;
      if (!validDuplicateTableNames(chapterData)) return false;
      return true;
    };

    //- validate
    const checkData = CommonService.deepClone(sustainReportChapterData);
    validateChapterTitle(checkData);
    validTable(checkData);

    setSustainReportChapterData(checkData);

    console.log("validateSaveData", valid, invalidMessage);

    //> alert invalidMessage
    if (!valid && invalidMessage) {
      error2Alert(invalidMessage);
      setTimeout(() => {
        const invalidNode = document.querySelector(".invalid");
        if (invalidNode) {
          //> 滑到致中間
          invalidNode.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    }

    return valid;
  };

  /** saveSustainReportChapterData */
  const saveSustainReportChapterData = () => {
    if (!childOutletIsDataChange) return;
    if (!validateSaveData()) return;
    //- handleSaveData
    const handleSaveData = (
      handleChapterData: SustainReportManuscriptDefaultDataModel
    ): SustainReportManuscriptDefaultDataModel => {
      const cloneData = CommonService.deepClone(handleChapterData);
      //- 如果表格沒有表格名稱, 則移除該表格
      cloneData.tableList = cloneData.tableList.filter((table) => {
        const valid =
          table.tableName !== "" &&
          table.tableName !== null &&
          table.tableName !== undefined;
        if (!valid) {
          cloneData.tableIdList = cloneData.tableIdList.filter(
            (id) => id !== table.tableId
          );
        }
        return valid;
      });
      return cloneData;
    };
    console.log('handleSaveData(sustainReportChapterData)', handleSaveData(sustainReportChapterData));

    editDefaultChapterDataMutate(handleSaveData(sustainReportChapterData));
  };

  const handleNoteChange = (tableId: string, newComment: string) => {
    setSustainReportChapterData((prevData) => {
      const updatedTableList = prevData.tableList.map((table) => {
        if (table.tableId === tableId) {
          return {
            ...table,
            comment: newComment,
          };
        }
        return table;
      });

      return {
        ...prevData,
        tableList: updatedTableList,
      };
    });

    // console.log('Updated comment for tableId:', tableId, 'New comment:', newComment);
  };

  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  const MemoizedMultiSelect = memo(MultiSelect);

  const multiSelectComponent = useMemo(() => {
    return (
      <MemoizedMultiSelect
        isFloatTitle={false}
        placeholder={""}
        options={griStandardOptionList}
        hideOptions={true}
        defaultIds={originSustainReportChapterData.griOptionsVal
          ?.filter((p) => p.checked === true)
          .map((p) => p.id)}
        onChange={(e) => {
          setSustainReportChapterData((prev) => {
            return {
              ...prev,
              griStandards: e.map((e) => parseInt(e)),
            };
          });
        }}
      />
    );
  }, [griStandardOptionList, originSustainReportChapterData.griStandards]);
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//

  //- useEffect init
  useEffect(() => {
    // console.log("manageId", manageId, ongoingManageId);
    // console.log("nodeId", nodeId, ongoingNodeId);
    // console.log(
    //   "selectedSustainReportFlatTreeItem",
    //   selectedSustainReportFlatTreeItem
    // );
    selectedSustainReportFlatTreeItemRef.current =
      selectedSustainReportFlatTreeItem!;
    if (!manageId || !nodeId || nodeId === ongoingNodeId) return;
    setOngoingManageId(manageId);
    setOngoingNodeId(nodeId);
    getGriStandardMutate();
  }, [
    manageId,
    nodeId,
    getDefaultChapterDataMutate,
    selectedSustainReportFlatTreeItem?.defaultId,
  ]);

  //- useEffect documnet body overflow-y hidden
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "";
    };
  }, []);

  useEffect(() => {
    try {
      const isEditorInitialLoadList: boolean[] = [true, true];
      const GRI_editor_model: jsEditorOptionModel = {
        id: "GRIEditor",
        init_instance_callback: (editor: any) => {
          editor.setContent(sustainReportChapterData.griIndex || "");
          isEditorInitialLoadList[0] = false;
          if (isEditorInitialLoadList.every((e) => !e)) {
            isEditorInitialLoad.current = false;
          }
        },
        setup: (editor: any) => {
          editor.on("change keyup", () => {
            if (!isEditorInitialLoad.current) {
              // console.log("GRIEditor");
              setSustainReportChapterData((prev) => ({
                ...prev,
                griIndex: editor.getContent(),
              }));
            }
          });
        },
      };
      GRI_editor.current = new jsEditor(GRI_editor_model);
      const reference_editor_model: jsEditorOptionModel = {
        id: "referenceEditor",
        init_instance_callback: (editor: any) => {
          editor.setContent(sustainReportChapterData.referenceExample || "");
          isEditorInitialLoadList[1] = false;
          if (isEditorInitialLoadList.every((e) => !e)) {
            isEditorInitialLoad.current = false;
          }
        },
        setup: (editor: any) => {
          editor.on("change keyup", () => {
            if (!isEditorInitialLoad.current) {
              // console.log("referenceEditor");
              setSustainReportChapterData((prev) => ({
                ...prev,
                referenceExample: editor.getContent(),
              }));
            }
          });
        },
      };
      reference_editor.current = new jsEditor(reference_editor_model);
    } catch (error) {
      console.log("editor init", error);
    }
    return () => {
      if (GRI_editor.current || reference_editor.current) {
        // console.log(
        //   "editor return",
        //   GRI_editor.current,
        //   reference_editor.current
        // );
        isEditorInitialLoad.current = true;
      }
    };
  }, [originSustainReportChapterData]);

  //- useEffect isDataChange
  useEffect(() => {
    const isDataChange = isDataEdited(
      sustainReportChapterData,
      originSustainReportChapterData
    );
    setLayoutData((prev) => {
      return {
        ...prev,
        childOutletIsDataChange: isDataChange,
      };
    });
  }, [sustainReportChapterData, originSustainReportChapterData]);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <div id="draft-management-container" className={`${styles["list-box"]}`}>
      {getDefaultChapterDataIsPending ||
        getGriStandardIsPending ||
        editDefaultChapterDataIsPending ? (
        <VisuallLoading />
      ) : null}
      <div className="list-title-box">
        <div className="left">編輯底稿</div>
        <div className="right">
          {detectEveryActionPermission([
            ReportManageActionsPermissionCodes.ReportCnUpdate,
          ]) ? (
            <button
              className={`default saveBtn
              ${!childOutletIsDataChange ? "customDisabled" : ""}
            `}
              onClick={saveSustainReportChapterData}
            >
              儲存
            </button>
          ) : null}
        </div>
      </div>
      <div
        className={`${styles["list-table-box"]} list-table-scroll-box scroll`}
      >
        <ScrollToTopButton
          targetClassName={"list-table-scroll-box"}
          bottom={90}
          right={35}
        />

        <div className="singleBox">
          <div className="title">
            章節名稱
            <span className="requireIcon">*</span>
          </div>
          <div className="inputBox chapterTitle">
            <NormalInput
              isFloatTitle={false}
              defaultValue={sustainReportChapterData.title}
              valid={sustainReportChapterData.titleCheckValid}
              onChange={(e) => {
                setSustainReportChapterData((prev) => {
                  return {
                    ...prev,
                    title: e,
                  };
                });
              }}
            />
          </div>
        </div>

        <div className="singleBox mt-3">
          <div className="title">底稿依循之GRI及申報數字連結</div>
          <div className="inputBox griSelect">{multiSelectComponent}</div>
        </div>

        <div className="divideLine"></div>

        <div className="singleBox">
          <div className="title mb-2">底稿設定</div>
          <div className="desc mb-2">
            申報參數帶入請使用 <code>{`<<>>`}</code>
            ，並於符號中填寫申報指標，欲請公司自行填入請使用 【XXXX】，並於符號中填寫申報說明。
          </div>
          <div className="inputBox">
            <NormalTextarea
              isFloatTitle={false}
              rows={10}
              placeholder=""
              defaultValue={sustainReportChapterData.manuscript}
              onChange={(e) => {
                setSustainReportChapterData((prev) => {
                  return {
                    ...prev,
                    manuscript: e,
                  };
                });
              }}
            />
          </div>
        </div>

        <div className="singleBox mt-3">
          {sustainReportChapterData.tableList?.length ? (
            <>
              {sustainReportChapterData.tableList.map((table, index) => {
                return (
                  <div
                    className={`settingTableBox ${index ? "mt-3" : ""}`}
                    key={table.tableId}
                  >
                    <div className="inputBox titleBox">
                      <NormalInput
                        isFloatTitle={true}
                        placeholder="表格大標"
                        defaultValue={table.tableName}
                        valid={table.tableNameCheckValid}
                        onChange={(e) => {
                          setSustainReportChapterData((prev) => {
                            const updatedTableList = prev.tableList?.map(
                              (t) => {
                                if (t.tableId === table.tableId) {
                                  return {
                                    ...t,
                                    tableName: e,
                                  };
                                }
                                return t;
                              }
                            );
                            return {
                              ...prev,
                              tableList: updatedTableList,
                              tableIdList: updatedTableList?.map(
                                (t) => t.tableId
                              ),
                            };
                          });
                        }}
                      />
                      <img
                        alt="delete"
                        src="/assets/images/buttonIcon/trash-icon.svg"
                        onClick={() => {
                          setSustainReportChapterData((prev) => {
                            const updatedTableList = prev.tableList?.filter(
                              (t) => t.tableId !== table.tableId
                            );
                            return {
                              ...prev,
                              tableList: updatedTableList,
                              tableIdList: updatedTableList?.map(
                                (t) => t.tableId
                              ),
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="inputBox tableRowColumn mt-3">
                      <NormalInput
                        isFloatTitle={false}
                        placeholder=""
                        type="number"
                        unit="欄"
                        defaultValue={table.columnSize}
                        onChange={(e) => {
                          if (!e || isNaN(e)) return;
                          setSustainReportChapterData((prev) => {
                            const updatedTableList = prev.tableList?.map(
                              (t) => {
                                if (t.tableId === table.tableId) {
                                  return {
                                    ...t,
                                    columnSize: parseInt(e),
                                  };
                                }
                                return t;
                              }
                            );

                            return updateTableCells({
                              ...prev,
                              tableList: updatedTableList,
                              tableIdList: updatedTableList?.map(
                                (t) => t.tableId
                              ),
                            });
                          });
                        }}
                      />
                      <span className="x">x</span>
                      <NormalInput
                        isFloatTitle={false}
                        placeholder=""
                        type="number"
                        unit="列"
                        defaultValue={table.rowSize}
                        onChange={(e) => {
                          if (!e || isNaN(e)) return;
                          setSustainReportChapterData((prev) => {
                            const updatedTableList = prev.tableList?.map(
                              (t) => {
                                if (t.tableId === table.tableId) {
                                  return {
                                    ...t,
                                    rowSize: parseInt(e),
                                  };
                                }
                                return t;
                              }
                            );
                            return updateTableCells({
                              ...prev,
                              tableList: updatedTableList,
                              tableIdList: updatedTableList?.map(
                                (t) => t.tableId
                              ),
                            });
                          });
                        }}
                      />
                    </div>
                    <div className="inputBox tableSetting mt-3">
                      {table.cells?.length ? (
                        <>
                          <table aria-label="table" className={`table-container`}>
                            <tbody>
                              {Array.from({ length: table.rowSize }).map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                  {Array.from({ length: table.columnSize }).map((_, colIndex) => (
                                    <td key={colIndex}>
                                      <NormalTextarea
                                        isFloatTitle={false}
                                        rows={2}
                                        placeholder=""
                                        defaultValue={
                                          table.cells.find(
                                            (cell) =>
                                              cell.rowIndex === rowIndex &&
                                              cell.columnIndex === colIndex
                                          )?.cellValue || ""
                                        }
                                        onChange={(e) => {
                                          setSustainReportChapterData((prev) => {
                                            const updatedTableList = prev.tableList?.map((t) => {
                                              if (t.tableId === table.tableId) {
                                                const updatedCells = t.cells.map((c) => {
                                                  if (
                                                    c.rowIndex === rowIndex &&
                                                    c.columnIndex === colIndex
                                                  ) {
                                                    return {
                                                      ...c,
                                                      cellValue: e,
                                                    };
                                                  }
                                                  return c;
                                                });
                                                return {
                                                  ...t,
                                                  cells: updatedCells,
                                                };
                                              }
                                              return t;
                                            });

                                            return {
                                              ...prev,
                                              tableList: updatedTableList,
                                              tableIdList: updatedTableList?.map((t) => t.tableId),
                                            };
                                          });
                                        }}
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {table.columnSize && table.rowSize ? (
                            <div
                              className="draft_note"
                              style={{ backgroundColor: table.rowSize % 2 === 0 ? '' : '#f4f8fa' }}
                            >
                              <NormalTextarea
                                isFloatTitle={true}
                                rows={2}
                                placeholder="請輸入表格附註"
                                defaultValue={table.comment}
                                onChange={(e) => {
                                  handleNoteChange(table.tableId, e);
                                }}
                              />
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}

          <div
            className={`addTableBtn ${sustainReportChapterData.tableList?.length ? "mt-3" : ""
              }`}
            onClick={() => {
              generateNewTable();
            }}
          >
            <img
              alt="sort"
              src="/assets/images/buttonIcon/blue-table-icon.svg"
            />
            新增表格
          </div>
        </div>

        <div className="divideLine"></div>

        <div className="singleBox">
          <div className="title">GRI指標</div>
          <div className="inputBox">
            <textarea id="GRIEditor"></textarea>
          </div>
        </div>

        <div className="singleBox mt-3">
          <div className="title">參考範例</div>
          <div className="inputBox">
            <textarea id="referenceEditor"></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraftSettings;
