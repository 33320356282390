import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./ManuscriptList.module.scss";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  ManuscriptRequestModel,
  SustainRreportManuscriptManageListModel,
  SustainRreportManuscriptModel,
} from "models/admin/manuscriptModel";
import ManuscriptService from "services/admin/manuscriptService";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import TablePagination from "components/base/TablePagination/TablePagination";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { normalDate3 } from "utils/dateTimeFormat";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import { error2Alert, success2Alert } from "utils/otherToast";
import { CommonService } from "services/common/commonService";
import { OptionModel } from "models/baseModel";
import Dropdown from "components/base/Dropdown/Dropdown";
import MultiSelect from "components/base/MultiSelect/MultiSelect";
import { usePermissionContext } from "context/PermissionProvider";
import { ReportManageActionsPermissionCodes } from "models/auth/permissionModel";
import CustDropdown from "components/base/Dropdown/Dropdown";
import useUserInfoStore from "state/useUserInfoStore";
import { AccountType } from "models/auth/authModel";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";

interface ManuscriptListProps {}

const ManuscriptList: FC<ManuscriptListProps> = () => {
  // #region 變數
  const [list, setList] = useState<SustainRreportManuscriptManageListModel[]>(
    []
  );
  const [orgList, setOrgList] = useState<
    SustainRreportManuscriptManageListModel[]
  >([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkCount, setCheckCount] = useState(0);
  const [openCheck, setOpenCheck] = useState(false);
  const [newList, setNewList] = useState<
    SustainRreportManuscriptManageListModel[]
  >([]);
  const [yearOp, setYearOp] = useState<OptionModel[]>(
    CommonService.getDefaultYearOp
  );
  const [defaultYear, setDefaultYear] = useState<string[]>([]);
  const [request, setRequest] = useState<ManuscriptRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
    sortType: null,
  });
  const [orderArray, setOrderArray] = useState<boolean[]>([true, true]);
  const navigate = useNavigate();
  const sortImg = "/assets/images/buttonIcon/sort-icon.svg";
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;

  /** userInfo */
  const { userInfo } = useUserInfoStore();
  /** isOTC */
  const isOTC = userInfo.accType === AccountType.Tpex;
  /** syncDraftsModal */
  const [syncDraftsModal, setSyncDraftsModal] = useState<ContentModalProps>({
    show: false,
    size: "sm",
    customClass: "sync-drafts-modal",
    title: "同步底稿",
    handleClose: () => {
      setSyncDraftsModal((prev) => ({ ...prev, show: false }));
    },
  });
  const [syncDraftsYear, setSyncDraftsYear] = useState<OptionModel[]>([]);
  const [selectedSyncDraftsYearId, setSelectedSyncDraftsYearId] = useState<
    string | null
  >(null);
  // #endregion

  useEffect(() => {
    setDefaultYear(yearOp.map((p) => p.id));
  }, []);

  // #region CRUD

  //取同步底稿清單用
  const {
    mutate: getManuscriptManageListMutate,
    isPending: getManuscriptManageListIsPending,
  } = useMutation({
    mutationFn: () =>
      ManuscriptService.getManuscriptManageList({
        page: 0,
        pageSize: 0,
        searchKey: null,
        sortKey: null,
        sortType: null,
      }),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const data: SustainRreportManuscriptManageListModel[] = res.data;
        setSyncDraftsYear(
          data
            ?.map((e) => ({
              id: e.id!.toString(),
              text: e.year!.toString(),
            }))
            .sort((a, b) => parseInt(b.text) - parseInt(a.text)) || []
        );
      } else {
        console.log(res.message);
        setSyncDraftsYear([]);
      }
    },
    onError: (error) => {
      console.log(error);
      setSyncDraftsYear([]);
    },
  });

  /** 櫃買同步年度底稿 */
  const { mutate: replaceOtcYearMutate, isPending: replaceOtcYearIsPending } =
    useMutation({
      mutationFn: (manageId: string) =>
        ManuscriptService.replaceOtcYear(manageId),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          success2Alert(res.message);
          setSyncDraftsModal((prev) => ({ ...prev, show: false }));
        } else {
          error2Alert(res.message);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  //取的清單
  const getManuscriptManageList = useMutation({
    mutationFn: (model: ManuscriptRequestModel) =>
      ManuscriptService.getManuscriptManageList(model),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const data = res.data;
        setOrgList(CommonService.deepClone(data));
        setList(CommonService.deepClone(data));
      } else {
        console.log(res.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    getManuscriptManageList.mutate(request);
  }, []);

  // 同步底稿
  const syncDrafts = () => {
    if (!selectedSyncDraftsYearId) {
      error2Alert("無同步年度");
      return;
    }
    replaceOtcYearMutate(selectedSyncDraftsYearId);
  };

  // 新增
  const addManuscriptManageData = useMutation({
    mutationFn: (model: SustainRreportManuscriptModel) =>
      ManuscriptService.addManuscriptManageData(model),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        getManuscriptManageList.mutate(request);
        success2Alert(res.message);
        setNewList([]);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (error) => {
      error2Alert("新增失敗");
      console.log(error);
    },
  });

  // 新增事件
  const handleAdd = (item: SustainRreportManuscriptManageListModel) => {
    if (!item.year || item.year === 0) {
      error2Alert("年度欄位必填");
    } else {
      addManuscriptManageData.mutate({ year: item.year });
    }
  };

  // 複製
  const copyMarketYearMutation = useMutation({
    mutationFn: (manageIds: string[]) =>
      ManuscriptService.copyManuscriptManageDataByIds(manageIds),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        getManuscriptManageList.mutate(request);
        success2Alert(res.message);
      } else {
        error2Alert("複製失敗");
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 刪除
  const deleteManuscriptManageDataByIds = useMutation({
    mutationFn: (manageIds: string[]) =>
      ManuscriptService.deleteManuscriptManageDataByIds(manageIds),
    onSuccess: (res) => {
      setConfirmModal({
        ...confirmModal,
        show: false,
        loading: false,
      });
      if (res.code === 200 && res.success) {
        getManuscriptManageList.mutate(request);
        success2Alert(res.message);
      } else {
        error2Alert("刪除失敗");
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  //刪除清單
  const handleRemove = (id: string | null) => {
    let ids: string[] = [];
    // null 批次刪除
    if (!id) {
      ids = list.filter((p) => p.checked).map((p) => p.id);
    } else {
      ids = [id!];
    }
    setConfirmModal({
      ...confirmModal,
      show: true,
      handleConfirm: () => {
        deleteManuscriptManageDataByIds.mutate(ids);
      },
    });
  };

  // 編輯
  const editManuscriptManageList = useMutation({
    mutationFn: (model: SustainRreportManuscriptManageListModel[]) =>
      ManuscriptService.editManuscriptManageList(model),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        getManuscriptManageList.mutate(request);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (error) => {
      error2Alert("編輯失敗");
      console.log(error);
    },
  });

  // 編輯事件
  const handleSave = (item: SustainRreportManuscriptManageListModel) => {
    if (item.year === null) {
      error2Alert("欄位必填");
    } else {
      editManuscriptManageList.mutate(list);
    }
  };

  // #endregion

  // #region 事件 & 確認事件
  // modal 物件(confirm)
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setConfirmModal({
        ...confirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認",
  });

  // 全選
  const handleAllCheckbox = () => {
    setCheckAll(!checkAll);
    if (!checkAll === true) {
      setCheckCount(list.length);
    } else {
      setCheckCount(0);
    }
    list.forEach((item) => {
      item.checked = !checkAll;
    });
    setList([...list]);
  };

  // 總數計算
  const countCheckbox = () => {
    const total = list.length;
    const checked = list.filter((x) => x.checked === true).length;
    setCheckCount(checked);
    if (total !== 0 && total === checked) {
      setCheckAll(true);
    } else if (checked !== 0 && total !== checked) {
      setCheckAll(false);
    } else {
      setCheckAll(false);
    }
  };

  const handleChkChange = (id: string | null) => {
    if (id) {
      setList(
        list.map((ck, index) => {
          if (ck.id === id) {
            ck.checked = !ck.checked;
          }
          return ck;
        })
      );
      countCheckbox();
    }
  };

  const handleTmpClick = () => {
    console.log("test");
  };

  // 打開編輯toggle
  const handleEditToggle = (index: number | null, isEdit: boolean) => {
    let tmpList = [...list];
    if (index === null) {
      tmpList.forEach((t) => {
        t.isEdit = false;
      });
    } else {
      console.log(orgList);
      tmpList[index].isEdit = isEdit;
      tmpList[index].year = orgList[index].year;
    }
    setList([...tmpList]);
  };
  // 選取年度開關
  const handleOpenCheckToggle = () => {
    setOpenCheck(!openCheck);
    setCheckAll(false);
    setCheckCount(0);
    setNewList([]);
    handleEditToggle(null, false);
    setList(
      list.map((ck, index) => {
        ck.checked = false;
        return ck;
      })
    );
  };

  /** openSyncDraftsModel */
  const handleOpenSyncDraftsModel = () => {
    setSelectedSyncDraftsYearId(null);
    getManuscriptManageListMutate();
    setSyncDraftsModal({
      ...syncDraftsModal,
      show: true,
    });
  };

  // 年度更換
  const yearChange = (year: string | null, index: number, isAdd: boolean) => {
    console.log(year);
    let realYear: number | null = null;
    if (year) {
      realYear = Number.parseInt(year);
    }
    if (isAdd === true) {
      setNewList(
        newList.map((item, i) => {
          if (index === i) {
            item.year = realYear;
          }
          return item;
        })
      );
    } else {
      setList(
        list.map((item, i) => {
          if (index === i) {
            item.year = realYear;
          }
          return item;
        })
      );
    }
  };

  // 查詢key
  const handleSearch = (key: string) => {
    const newObj = {
      ...request,
      searchKey: key,
      page: 1,
    };
    setRequest(newObj);
    getManuscriptManageList.mutate(newObj);
  };

  // 排序
  const handleSort = (key: string, index: number) => {
    let sortTyep = true;
    setOrderArray(
      orderArray.map((p, itemIndex) => {
        if (itemIndex === index) {
          p = !p;
          sortTyep = p;
        }
        return p;
      })
    );
    const newReq = {
      ...request,
      sortKey: key,
      sortType: sortTyep,
    };
    setRequest(newReq);
    getManuscriptManageList.mutate(newReq);
  };

  // 複製(一次一個)
  const handleCopy = (id: string | null) => {
    let ids: string[] = [];

    if (id) {
      ids = [id!];
      copyMarketYearMutation.mutate(ids);
    }
    if (!id) {
      ids = list.filter((p) => p.checked).map((p) => p.id);
      if (ids.length !== 1) {
        error2Alert("僅能選取一筆資料");
        return;
      }
      copyMarketYearMutation.mutate(ids);
    }
  };

  // #endregion

  // 前往細節頁
  const handleNavigate = (item: SustainRreportManuscriptManageListModel) => {
    if (item.id) {
      navigate(`/admin/manuscript/${item.id}/zh/draft-management`);
    }
  };

  /*const handleChangeSyncYear = (str: string | null) => {
    selSyncYear.current = str;

  };

   // 處理modal關閉事件
   const handleCloseModal = () => {
    selSyncYear.current = null;
    setModal((prev: any) => {
      return {
          ...prev,
          show: !prev.show,
      };
    });
  };

  // 同步提交
  const handleSubmitSyncModal = () => {
    console.log(selSyncYear.current);
    //handleCloseModal();
  };

  useEffect(() => {
    setModal((prev) => ({
      ...prev,
      handleClose: () => handleCloseModal(),
      handleConfirm: () => handleSubmitSyncModal(),
    }));
  }, [modal.show]);*/

  return (
    <>
      <div className="no-nav-content-box">
        <div className={styles["manuscript-box"]}>
          {/*上方bar*/}
          <div className="title-box">
            <div className="title">報告書底稿管理</div>
          </div>
          {getManuscriptManageList.isPending ? <VisuallLoading /> : <></>}
          <div className="tool-box mt-20-p">
            <div className="tool-box">
              {!openCheck ? (
                <div className="mr-1-m">
                  <MultiSelect
                    onChange={(ids: string[]) => {
                      const str = ids.join(",");
                      handleSearch(str);
                    }}
                    defaultIds={defaultYear}
                    showAll={true}
                    placeholder={"年度"}
                    options={yearOp}
                  />
                </div>
              ) : (
                <div className="tool-box mt-10-p">
                  <div className="sel-item">已選取{checkCount}項目</div>
                  {checkCount > 0 && (
                    <>
                      {detectEveryActionPermission([
                        ReportManageActionsPermissionCodes.ReportListUpdate,
                      ]) ? (
                        <div className="icon-button">
                          <LinkIconButton
                            imgName={`${
                              checkCount === 1
                                ? "copy-icon.svg"
                                : "copy-lock-icon.svg"
                            }`}
                            text="複製"
                            className={`${checkCount === 1 ? "" : "lock"}`}
                            onClick={
                              checkCount === 1
                                ? () => handleCopy(null)
                                : () => {}
                            }
                          />
                        </div>
                      ) : null}
                      {detectEveryActionPermission([
                        ReportManageActionsPermissionCodes.ReportListDelete,
                      ]) ? (
                        <div className="icon-button">
                          <LinkIconButton
                            imgName={`${
                              checkCount > 0
                                ? "trash-icon.svg"
                                : "trash-lock-icon.svg"
                            }`}
                            text="刪除"
                            className={`${checkCount > 0 ? "" : "lock"}`}
                            onClick={
                              checkCount > 0
                                ? () => handleRemove(null)
                                : () => {}
                            }
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="tool-box">
              {isOTC &&
              detectEveryActionPermission([
                ReportManageActionsPermissionCodes.ReportListUpdate,
              ]) ? (
                <button
                  className="secondary mr-1-m"
                  onClick={handleOpenSyncDraftsModel}
                >
                  同步底稿
                </button>
              ) : null}
              <button
                className="secondary mr-1-m"
                onClick={handleOpenCheckToggle}
              >
                {!openCheck ? "多筆編輯" : "取消選取"}
              </button>
              {detectEveryActionPermission([
                ReportManageActionsPermissionCodes.ReportListCreate,
              ]) ? (
                <button
                  className="default mr-1-m"
                  onClick={() => {
                    setNewList([
                      ...newList,
                      {
                        id: (newList.length++).toString(),
                        year: 0,
                        lastEditDate: new Date(),
                        checked: false,
                        isEdit: false,
                      },
                    ]);
                    setOpenCheck(false);
                  }}
                >
                  +新增年度
                </button>
              ) : null}
            </div>
          </div>

          {/** 主表 */}
          <div className="result-box scroll">
            <div className="table-box ">
              <table
                aria-label="查詢結果table"
                className="table-container sticky-table"
              >
                <thead>
                  <tr>
                    {openCheck && (
                      <th style={{ width: "50px" }} scope="col">
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            checked={checkAll}
                            onChange={() => handleAllCheckbox()}
                          />
                          <span>&nbsp;</span>
                        </label>
                      </th>
                    )}
                    <th scope="col">
                      <div className="sort">
                        年度
                        <img
                          className={orderArray[0] ? "" : "desc"}
                          alt="year"
                          src={sortImg}
                          onClick={() => {
                            handleSort("year", 0);
                          }}
                        />
                      </div>
                    </th>
                    <th scope="col">
                      <div className="sort">
                        編輯日期
                        <img
                          className={orderArray[1] ? "" : "desc"}
                          alt="date"
                          src={sortImg}
                          onClick={() => {
                            handleSort("lastEditDate", 1);
                          }}
                        />
                      </div>
                    </th>
                    <th scope="col" style={{ width: "40px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {list.length === 0 ? (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={3}>
                        查無資料
                      </td>
                    </tr>
                  ) : (
                    list.map((p, i) => {
                      return (
                        <tr key={p.id}>
                          {/*開啟checkbox的td*/}
                          {openCheck && (
                            <td>
                              <label className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  checked={p.checked}
                                  onChange={() => handleChkChange(p.id)}
                                />
                                <span>&nbsp;</span>
                              </label>
                            </td>
                          )}
                          {/*年度*/}
                          {openCheck || p.isEdit !== true ? (
                            <td
                              onClick={() => {
                                handleNavigate(p);
                              }}
                            >
                              {p.year}
                            </td>
                          ) : (
                            <td>
                              <Dropdown
                                isFloatTitle={false}
                                options={yearOp}
                                placeholder="年度"
                                defaultId={p.year?.toString()}
                                onChange={(e) => yearChange(e, i, false)}
                              />
                            </td>
                          )}
                          {/*編輯日期*/}
                          <td
                            onClick={() => {
                              handleNavigate(p);
                            }}
                          >
                            {p.lastEditDate ? normalDate3(p.lastEditDate) : "-"}
                          </td>
                          {!openCheck ? (
                            <td>
                              {p.isEdit === true ? (
                                /** 儲存與取消 */
                                <div className="d-flex">
                                  {detectEveryActionPermission([
                                    ReportManageActionsPermissionCodes.ReportListUpdate,
                                  ]) ? (
                                    <>
                                      <LinkIconButton
                                        imgName="check-icon.svg"
                                        text=""
                                        imgStyle={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                        onClick={() => handleSave(p)}
                                      />
                                      <LinkIconButton
                                        imgName="cross-icon.svg"
                                        text=""
                                        imgStyle={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                        onClick={() =>
                                          handleEditToggle(i, false)
                                        }
                                      />
                                    </>
                                  ) : null}
                                </div>
                              ) : (
                                <>
                                  {detectEveryActionPermission([
                                    ReportManageActionsPermissionCodes.ReportListUpdate,
                                  ]) ? (
                                    /* 開啟編輯*/
                                    <LinkIconButton
                                      imgName="right-arrow.svg"
                                      text=""
                                      onClick={() => handleEditToggle(i, true)}
                                    />
                                  ) : null}
                                </>
                              )}
                            </td>
                          ) : (
                            <td>
                              {/** 複製與刪除 */}
                              <div className="d-flex">
                                {detectEveryActionPermission([
                                  ReportManageActionsPermissionCodes.ReportListUpdate,
                                ]) ? (
                                  <LinkIconButton
                                    imgName="copy-icon.svg"
                                    text=""
                                    className={"hover"}
                                    onClick={() => handleCopy(p.id)}
                                  />
                                ) : null}
                                {detectEveryActionPermission([
                                  ReportManageActionsPermissionCodes.ReportListDelete,
                                ]) ? (
                                  <LinkIconButton
                                    imgName="trash-icon.svg"
                                    text=""
                                    className={"hover"}
                                    onClick={() => handleRemove(p.id)}
                                  />
                                ) : null}
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                  {/* 確認刪除modal */}
                  <ConfirmModal {...confirmModal}>
                    <div>是否刪除資料?</div>
                  </ConfirmModal>
                  {/* 新增資料 */}
                  {newList.map((n, i) => {
                    return (
                      <tr key={n.id}>
                        <td>
                          <Dropdown
                            isFloatTitle={false}
                            options={yearOp}
                            placeholder="年度"
                            onChange={(e) => yearChange(e, i, true)}
                          />
                        </td>
                        <td>-</td>
                        <td>
                          <div className="d-flex">
                            <LinkIconButton
                              imgName="check-icon.svg"
                              text=""
                              imgStyle={{ width: "24px", height: "24px" }}
                              onClick={() => handleAdd(n)}
                            />
                            <LinkIconButton
                              imgName="cross-icon.svg"
                              text=""
                              imgStyle={{ width: "24px", height: "24px" }}
                              onClick={() => {
                                setNewList(
                                  newList.filter((a) => a.id !== n.id)
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* 分頁 */}
          <div className="pad-10 table-pagination">
            <TablePagination
              page={request.page}
              pageSize={request.pageSize}
              onChange={(e) => {
                const newReq = {
                  ...request,
                  pageSize: e.pageSize,
                  page: e.page,
                };
                setRequest(newReq);
                getManuscriptManageList.mutate(newReq);
              }}
              total={orgList.length || 0}
            />
          </div>
        </div>
      </div>
      {/* SyncDraftModel */}
      {isOTC && (
        <ContentModal {...syncDraftsModal}>
          {getManuscriptManageListIsPending || replaceOtcYearIsPending ? (
            <VisuallLoading />
          ) : null}
          <div className={`${styles["open-declaration-modal-content"]}`}>
            <div className={`content-box`}>
              <div className="row-box">
                <div className="title">請選擇同步年度</div>
                <div className="data-input">
                  <Dropdown
                    placeholder={"年度"}
                    options={syncDraftsYear}
                    isFloatTitle={false}
                    onChange={(e) => {
                      setSelectedSyncDraftsYearId(e);
                    }}
                  />
                </div>
              </div>
              <div className="reminder">
                同步底稿將會覆蓋原有底稿內容，請確認是否更新
              </div>
            </div>
            <div className={`footer-box`}>
              <button
                className={`secondary`}
                onClick={() => {
                  setSyncDraftsModal((prev) => ({ ...prev, show: false }));
                }}
              >
                取消
              </button>
              <button
                className={`default`}
                onClick={() => {
                  syncDrafts();
                }}
              >
                確定
              </button>
            </div>
          </div>
        </ContentModal>
      )}
    </>
  );
};

export default ManuscriptList;
