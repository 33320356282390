import { EditCorrectionRequestEmailModel } from "models/admin/correctionRequestEmailManageModel";
import { ListRequestModel } from "models/baseModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

/** 取得更正申請信箱管理清單 */
const getCorrectionRequestEmailList = (request: ListRequestModel) => {
    return httpClient.post(`CorrectionRequestEmailManage/list`, request);
}

/** 新增更正申請信箱 */
const addCorrectionRequestEmail = (model: EditCorrectionRequestEmailModel) => {
    return httpClient.post(`CorrectionRequestEmailManage/add`, model);
}

/** 編輯更正申請信箱 */
const editCorrectionRequestEmail = (model: EditCorrectionRequestEmailModel) => {
    return httpClient.post(`CorrectionRequestEmailManage/${model.id}`, model);
}

/** 批次刪除更正申請信箱 */
const deleteCorrectionRequestEmail = (ids: string[]) => {
    return httpClient.post(`CorrectionRequestEmailManage/delete`, ids);
}

const CorrectionRequestEmailManageService = {
    getCorrectionRequestEmailList,
    addCorrectionRequestEmail,
    editCorrectionRequestEmail,
    deleteCorrectionRequestEmail
}

export default CorrectionRequestEmailManageService;