//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, createContext } from "react";
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams, Outlet } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import { SustainQuestionnaireSampleModel } from "models/application/sustainQuestionnaire/sustainQuestionnaireModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import BackTitle from "components/base/BackTitle/BackTitle";
import { NavBarItemModel } from "models/baseModel";
import styles from "./DraftSettingsLayout.module.scss";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import ManuscriptService from "services/admin/manuscriptService";
import {
  SustainRreportManuscriptManageDataModel,
  ManuscriptEditionEnum,
} from "models/admin/manuscriptModel";
import TabVerticalCategoryThin from "components/base/TabVerticalCategoryThin/TabVerticalCategoryThin";
import { CategoryNavBarListModel } from "models/baseModel";
import {
  SustainReportDefaultFlatTreeNodeModel,
  SustainReportDefaultLevelTreeNodeModel,
} from "models/admin/manuscriptModel";
import { success2Alert, error2Alert } from "utils/otherToast";
import { CommonService } from "services/common/commonService";
//* ---------------------------- local end ---------------------------- *//

export const LayoutContext = createContext<{
  setLayoutData: React.Dispatch<
    React.SetStateAction<{
      sustainReportFlatTree: SustainReportDefaultFlatTreeNodeModel[];
      selectedSustainReportFlatTreeItem?: SustainReportDefaultFlatTreeNodeModel;
      childOutletIsDataChange: boolean;
    }>
  >;
  sustainReportFlatTree: SustainReportDefaultFlatTreeNodeModel[];
  selectedSustainReportFlatTreeItem?: SustainReportDefaultFlatTreeNodeModel;
  childOutletIsDataChange: boolean;
  getDefaultSustainReportFlatTreeMutate?: (manageId: string) => void;
}>({
  setLayoutData: () => {},
  sustainReportFlatTree: [],
  selectedSustainReportFlatTreeItem: undefined,
  childOutletIsDataChange: false,
  getDefaultSustainReportFlatTreeMutate: () => {},
});

const DraftSettingsLayout: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  /** navigate */
  const navigate = useNavigate();
  /** params */
  const params = useParams();
  /** manageId */
  const manageId = params.id;
  /** nodeId */
  const nodeId = params.nodeId;
  /** manuscriptEdition */
  const [manuscriptEdition, setManuscriptEdition] =
    useState<ManuscriptEditionEnum>();
  /** sustainReportFlatTree */
  const [sustainReportFlatTree, setSustainReportFlatTree] = useState<
    SustainReportDefaultFlatTreeNodeModel[]
  >([]);
  /** DraftSettingsLayoutNavItems */
  const [DraftSettingsLayoutNavItems, setDraftSettingsLayoutNavItems] =
    useState<CategoryNavBarListModel[]>([]);
  /** layoutData */
  const [layoutData, setLayoutData] = useState<{
    sustainReportFlatTree: SustainReportDefaultFlatTreeNodeModel[];
    selectedSustainReportFlatTreeItem?: SustainReportDefaultFlatTreeNodeModel;
    childOutletIsDataChange: boolean;
  }>({
    sustainReportFlatTree: [],
    selectedSustainReportFlatTreeItem: undefined,
    childOutletIsDataChange: false,
  });
  /** selectedNavItemUrl */
  const [selectedNavItemUrl, setSelectedNavItemUrl] = useState<string>();
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  /** getDefaultSustainReportFlatTree */
  const {
    mutate: getDefaultSustainReportFlatTreeMutate,
    isPending: getDefaultSustainReportFlatTreeIsPending,
  } = useMutation({
    mutationFn: (manageId: string) =>
      ManuscriptService.getDefaultSustainReportFlatTree(manageId),
    onSuccess: (res) => {
      // console.log("getDefaultSustainReportFlatTree", res);
      if (res.code === 200 && res.success) {
        const preHandleData = preHandleBeforeSetData(
          refactorTreeForWebUse(res.data)
        );
        // console.log("getDefaultSustainReportFlatTree", preHandleData);
        setSustainReportFlatTree(preHandleData);
        setLayoutData((prev) => ({
          ...prev,
          ...{
            sustainReportFlatTree: preHandleData,
            selectedSustainReportFlatTreeItem: preHandleData.find(
              (item) => item.defaultId === nodeId
            ),
          },
        }));
        // console.log(
        //   "refactorLayoutNavList",
        //   refactorLayoutNavList(preHandleData)
        // );
        setDraftSettingsLayoutNavItems([
          ...refactorLayoutNavList(preHandleData),
        ]);
      } else {
        setSustainReportFlatTree([]);
        setDraftSettingsLayoutNavItems([]);
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      setSustainReportFlatTree([]);
      setDraftSettingsLayoutNavItems([]);
      console.log("getDefaultSustainReportFlatTree", err);
      error2Alert("執行失敗");
    },
  });

  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  /** refactorLayoutNavList */
  const refactorLayoutNavList = (
    data: SustainReportDefaultFlatTreeNodeModel[]
  ): CategoryNavBarListModel[] => {
    const layoutNavList: CategoryNavBarListModel[] = [];
    data.forEach((item) => {
      if (item.level === 0) {
        layoutNavList[layoutNavList.length] = {
          name: item.webChapterIndexTitle,
          category: item.defaultId,
          id: item.defaultId,
          customClassName: "customCategoryTitle mt-2",
          disabled: false,
          list: [],
        };
      } else {
        layoutNavList[layoutNavList.length - 1].list.push({
          name: item.webChapterIndexTitle,
          url: `/admin/manuscript/${manageId}/${manuscriptEdition}/draft-management/${item.defaultId}/settings`,
          key: item.defaultId,
          disabled: false,
        });
      }
    });
    return layoutNavList;
  };

  /** refactorTreeForWebUse */
  const refactorTreeForWebUse = (
    items: SustainReportDefaultFlatTreeNodeModel[]
  ): SustainReportDefaultFlatTreeNodeModel[] => {
    return items.map((item) => {
      return {
        ...new SustainReportDefaultFlatTreeNodeModel(),
        ...item,
      };
    });
  };

  /** preHandleBeforeSetData */
  const preHandleBeforeSetData = (
    data: SustainReportDefaultFlatTreeNodeModel[]
  ): SustainReportDefaultFlatTreeNodeModel[] => {
    const preHandleItemPropertyData = preHandleItemProperty(data);
    return CommonService.deepClone(preHandleItemPropertyData);
  };

  /** preHandleItemData */
  const preHandleItemProperty = (
    data: SustainReportDefaultFlatTreeNodeModel[]
  ): SustainReportDefaultFlatTreeNodeModel[] => {
    const handleData = CommonService.deepClone(data);
    const rootNodes: SustainReportDefaultLevelTreeNodeModel[] = [];
    const nodeMap = new Map<string, SustainReportDefaultLevelTreeNodeModel>();

    const findParentNode = (
      node: SustainReportDefaultLevelTreeNodeModel,
      nodeMap: Map<string, SustainReportDefaultLevelTreeNodeModel>
    ): SustainReportDefaultLevelTreeNodeModel | null => {
      const parentLevel = node.level - 1;
      const parentNodes = Array.from(nodeMap.values()).filter(
        (n) => n.level === parentLevel
      );

      for (let i = parentNodes.length - 1; i >= 0; i--) {
        if (parentNodes[i].level === parentLevel) {
          return parentNodes[i];
        }
      }
      return null;
    };

    handleData.forEach((item) => {
      const node = Object.assign(
        new SustainReportDefaultLevelTreeNodeModel(),
        item
      );
      node.children = [];
      nodeMap.set(node.uniqueKey, node);

      if (node.level === 0) {
        rootNodes.push(node);
      } else {
        const parentNode = findParentNode(node, nodeMap);
        if (parentNode) {
          parentNode.children.push(node);
          node.parentId = parentNode.uniqueKey;
        }
      }
    });

    let parentChapterIndex = "";
    const handleItemDataProperty = (
      data: SustainReportDefaultLevelTreeNodeModel[],
      parentChapterIndex: string = "",
      level: number = 0
    ) => {
      data.forEach((item, index) => {
        //- parentChapterIndex
        if (level === 1) {
          const chapterIndex = index > 8 ? index + 1 : "0" + (index + 1);
          item.webChapterIndex = parentChapterIndex + chapterIndex;
        } else {
          item.webChapterIndex = parentChapterIndex + (index + 1).toString();
        }
        //- webChapterIndexTitle
        item.webChapterIndexTitle = `${item.webChapterIndex} ${item.title}`;

        //- find handleData same uniqueKey and set porperty
        const findSameUniqueKeyNode = handleData.find(
          (findItem) => findItem.uniqueKey === item.uniqueKey
        );
        if (findSameUniqueKeyNode) {
          findSameUniqueKeyNode.webChapterIndex = item.webChapterIndex;
          findSameUniqueKeyNode.webChapterIndexTitle =
            item.webChapterIndexTitle;
        }

        handleItemDataProperty(
          item.children,
          item.webChapterIndex + ".",
          level + 1
        );
      });
    };
    handleItemDataProperty(rootNodes, parentChapterIndex);

    // console.log("handleData", handleData);

    return handleData;
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //- changeNavItem
  const changeNavItem = (item: NavBarItemModel) => {
    try {
      navigate(item.url, { replace: true });
    } catch (error) {
      console.log("changeNavItem", error);
    }
  };
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- useEffect init
  useEffect(() => {
    // console.log(id);
    if (!manageId) return;
    getDefaultSustainReportFlatTreeMutate(manageId);
  }, [manageId, getDefaultSustainReportFlatTreeMutate]);

  //- useEffect navItems
  useEffect(() => {
    if (manageId) {
      const url = params["*"];
      const path = url?.split(`${manageId}/`).pop();
      const pathArr = path?.split("/");
      const edition = pathArr?.shift();
      const findManuscriptEdition =
        ManuscriptEditionEnum[edition as keyof typeof ManuscriptEditionEnum];
      if (findManuscriptEdition) {
        setManuscriptEdition(findManuscriptEdition);
      }
      if (nodeId) {
        // console.log("nodeId", nodeId);
        setSelectedNavItemUrl(`/admin/manuscript/${url}`);
        setLayoutData((prev) => ({
          ...prev,
          ...{
            sustainReportFlatTree: sustainReportFlatTree,
            selectedSustainReportFlatTreeItem: sustainReportFlatTree.find(
              (item) => item.defaultId === nodeId
            ),
          },
        }));
      }
    }
  }, [manageId, navigate, sustainReportFlatTree]);

  useEffect(() => {
    // console.log("layoutData", layoutData);
  }, [layoutData]);

  // useEffect(() => {
  //   console.log("DraftSettingsLayoutNavItems", DraftSettingsLayoutNavItems);
  // }, [DraftSettingsLayoutNavItems]);

  //* ---------------------------- hook end ---------------------------- *//

  return (
    <CustomErrorBoundary>
      <div className="no-nav-content-box">
        <div className={`${styles["sustain-questionnaire-box"]}`}>
          {getDefaultSustainReportFlatTreeIsPending ? <VisuallLoading /> : null}
          <div className="title-box">
            <BackTitle
              title={`章節底稿設定${
                manuscriptEdition
                  ? manuscriptEdition === "zh"
                    ? "-中文版"
                    : "-英文版"
                  : ""
              }`}
              url={`/admin/manuscript/${manageId}/${manuscriptEdition}/draft-management`}
            />
          </div>

          <div className="sustain-questionnaire-content-box position-relative">
            {!DraftSettingsLayoutNavItems.length ? (
              <>
                {!getDefaultSustainReportFlatTreeIsPending ? (
                  <div className="no-data">沒有資料</div>
                ) : null}
              </>
            ) : (
              <>
                {/* {getManuscriptManageDataByIdIsPending ? <VisuallLoading /> : null} */}
                {/* 左區塊 */}
                <div className="left-box">
                  <TabVerticalCategoryThin
                    data={DraftSettingsLayoutNavItems}
                    tabUrl={selectedNavItemUrl!}
                    handleOtherEvent={true}
                    changeItem={changeNavItem}
                    isNeedPrompt={true}
                    isDataChange={layoutData.childOutletIsDataChange}
                    promptDisplayText={
                      "您有未保存的變更，確定要離開這個頁面嗎？"
                    }
                  />
                </div>

                {/* 右區塊 */}
                <div className={"right-box"}>
                  <div className="sustain-questionnaire-inside-content-box scroll position-relative">
                    <ScrollToTopButton
                      targetClassName={
                        "sustain-questionnaire-inside-content-box"
                      }
                      bottom={90}
                      right={35}
                    />
                    <LayoutContext.Provider
                      value={{
                        ...layoutData,
                        setLayoutData,
                        getDefaultSustainReportFlatTreeMutate,
                      }}
                    >
                      <Outlet></Outlet>
                    </LayoutContext.Provider>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </CustomErrorBoundary>
  );
};

export default DraftSettingsLayout;
