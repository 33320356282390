//* ---------------------------- React start ---------------------------- *//
import React, {
  FC,
  createContext,
  useContext,
  ReactNode,
  useEffect,
} from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { Navigate, RouteObject, useNavigate } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import useUserInfoStore from "state/useUserInfoStore";
import {
  PagePermissionCodes,
  PermissionRouteObjectModel,
  PermissionSideBarItemModel,
  ActionsPermissionCodes,
  FeaturePermissionCodes,
} from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
//* ---------------------------- local end ---------------------------- *//

//~ ---------------------------- PermissionContext start ---------------------------- ~//
interface PermissionContextValue {
  PermissionState: {
    noPermissionPage: (backgroundColor?: string) => JSX.Element;
  };
  PermissionService: {
    detectSomePagePermission: (code: string[]) => boolean;
    detectEveryPagePermission: (code: string[]) => boolean;
    detectSomeFeatruePermission: (code: string[]) => boolean;
    detectEveryFeatruePermission: (code: string[]) => boolean;
    detectSomeActionPermission: (code: string[]) => boolean;
    detectEveryActionPermission: (code: string[]) => boolean;
    isAnyPagePermission: () => boolean;
    permissionRouter: (routers: PermissionRouteObjectModel[]) => RouteObject[];
    LogAndNavigate: ({ to }: { to: string }) => JSX.Element | null;
  };
}

const PermissionContext = createContext<PermissionContextValue | null>(null);

interface MyProviderProps {
  children: ReactNode;
}

export const PermissionProvider: React.FC<MyProviderProps> = ({ children }) => {
  //* ---------------------------- state start ---------------------------- *//
  //* userInfo
  const { userInfo } = useUserInfoStore();
  //* permission
  const { permission } = userInfo;
  //* roleNames, pageCodes, featureCodes, actionCodes
  const { roleNames, pageCodes, featureCodes, actionCodes } = permission;
  //* noPermissionPage
  const noPermissionPage = (backgroundColor?: string) => (
    <EmptyWaitPage
      text="此帳號無權限"
      customStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: backgroundColor || "#fff",
      }}
    />
  );
  const PermissionState = {
    noPermissionPage,
  };
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//

  //- detectSomePagePermission
  const detectSomePagePermission = (code: string[]) => {
    return code.some((c) => pageCodes.includes(c));
  };
  //- detectEveryPagePermission
  const detectEveryPagePermission = (code: string[]) => {
    return code.every((c) => pageCodes.includes(c));
  };
  //- detectSomeFeatruePermission
  const detectSomeFeatruePermission = (code: string[]) => {
    return code.some((c) => featureCodes.includes(c));
  };
  //- detectEveryFeatruePermission
  const detectEveryFeatruePermission = (code: string[]) => {
    return code.every((c) => featureCodes.includes(c));
  };
  //- detectSomeActionPermission
  const detectSomeActionPermission = (code: string[]) => {
    return code.some((c) => actionCodes.includes(c));
  };
  //- detectEveryActionPermission
  const detectEveryActionPermission = (code: string[]) => {
    return code.every((c) => actionCodes.includes(c));
  };

  //- isAnyPagePermission
  const isAnyPagePermission = () => {
    return pageCodes.length > 0;
  };

  //- LogAndNavigate
  const LogAndNavigate = ({ to }: { to: string }) => {
    const navigate = useNavigate();

    useEffect(() => {
      console.log(`! - Auto Navigating to ${to}`);
      navigate(to);
    }, [to, navigate]);

    return null; // 這個元件不渲染任何內容
  };

  //- permissionRouter
  const permissionRouter = (routers: PermissionRouteObjectModel[]) => {
    const needRouteCheck = (
      pagePermission?: string | boolean,
      featurePermission?: string | boolean
    ) => {
      const hasPagePermission =
        pagePermission === undefined ||
        (typeof pagePermission === "string" &&
          pageCodes.includes(pagePermission)) ||
        (typeof pagePermission === "boolean" && pagePermission);
      const hasFeaturePermission =
        featurePermission === undefined ||
        (typeof featurePermission === "string" &&
          featureCodes.includes(featurePermission)) ||
        (typeof featurePermission === "boolean" && featurePermission);

      return hasPagePermission && hasFeaturePermission;
    };

    const displayReadCheck = (actionReadPermission?: string | boolean) => {
      const hasActionReadPermission =
        actionReadPermission === undefined ||
        (typeof actionReadPermission === "string" &&
          actionCodes.includes(actionReadPermission)) ||
        (typeof actionReadPermission === "boolean" && actionReadPermission);

      return hasActionReadPermission;
    };

    const generateRoutes = (
      routers: PermissionRouteObjectModel[]
    ): RouteObject[] => {
      const returnRouters: RouteObject[] = [];

      routers.forEach((routerItem) => {
        const {
          path,
          element,
          children,
          pagePermission,
          featurePermission,
          actionReadPermission,
        } = routerItem;

        if (needRouteCheck(pagePermission, featurePermission)) {
          returnRouters.push({
            path,
            element: displayReadCheck(actionReadPermission)
              ? element
              : noPermissionPage(),
            children:
              children && children.length && generateRoutes(children).length
                ? generateRoutes(children)
                : undefined,
          });
        } else if (
          children &&
          children.length &&
          generateRoutes(children).length
        ) {
          returnRouters.push({
            path,
            children: generateRoutes(children),
          });
        }
      });


      //- 補上默認路由
      //> 尋找第一個沒有路徑變數的路由
      const nonParamRoute = returnRouters.find(
        (router) => router.path && !router.path.includes(":")
      );
      if (returnRouters.length && nonParamRoute) {
        const autoRouter = nonParamRoute.path!.replace(/\/.*/, "");
        // console.log("autoRouter", autoRouter);
        returnRouters.push(
          {
            path: "",
            element: <LogAndNavigate to={autoRouter} />,
          },
          {
            path: "*",
            element: <LogAndNavigate to={autoRouter} />,
          }
        );
      } else {
        returnRouters.push(
          {
            path: "",
            element: noPermissionPage(),
          },
          {
            path: "*",
            element: <LogAndNavigate to={""} />,
          }
        );
      }

      return returnRouters;
    };
    const returnMainRouters = generateRoutes(routers);
    //- 在第一層(最上面一層)如果都沒有路由，補上一個預設路由，並給一個noPermissionPage
    if (!returnMainRouters.length) {
      returnMainRouters.push(
        {
          path: "",
          element: noPermissionPage(),
        },
        {
          path: "*",
          element: <LogAndNavigate to={""} />,
        }
      );
    }
    // console.log("returnMainRouters", returnMainRouters);
    return returnMainRouters;
  };

  const PermissionService = {
    detectSomePagePermission,
    detectEveryPagePermission,
    detectSomeFeatruePermission,
    detectEveryFeatruePermission,
    detectSomeActionPermission,
    detectEveryActionPermission,
    isAnyPagePermission,
    permissionRouter,
    LogAndNavigate,
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <PermissionContext.Provider value={{ PermissionState, PermissionService }}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissionContext = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error(
      "usePermissionContext must be used within a PermissionProvider"
    );
  }
  return context;
};
//~ ---------------------------- PermissionContext end ---------------------------- ~//
