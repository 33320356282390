import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import {
  FillType,
  SustainReportUserTreeNode,
} from "models/application/sustainReportDeclare/sustainReportProduceModel";
import Container from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep1/GroupTree/Container";
import React, { FC } from "react";

interface WrapperProps {
  group: SustainReportUserTreeNode;
  /** 索引遞迴路徑 */
  groupsIndex: number[];
  /** groupTree 根目錄設置陣列方法 */
  setList: (groupsIndex: number[], groups: SustainReportUserTreeNode[]) => void;
  onEnd: () => void;
  isRoot?: boolean;
  /**  點選擇Item 項目 */
  clickItem: (
    groupsIndex: number[],
    group: SustainReportUserTreeNode,
    type: string
  ) => void;
}

const Wrapper: FC<WrapperProps> = (props) => {
  const { group, setList, clickItem, groupsIndex } = props;

  /** 取得動態計算code */
  const getCode = (): string => {
    let code = "";
    groupsIndex.forEach((p, index) => {
      if (index === 1) {
        // 特別多一個0
        code += `0${p + 1}`;
      } else {
        code += `${p + 1}`;
      }

      // 最後一個不加 -
      if (index < groupsIndex.length - 1) {
        code += ".";
      }
    });
    return code;
  };

  /** 取得顏色 */
  const getColor = (fillType:FillType): string => {
    let color = "color1";
    switch (fillType) {
      case FillType.Suggestion:
        color = "color2";
        break;
      case FillType.Other:
        color = "color3";
        break;
      case FillType.Necessary:
      default:
        color = "color1";
        break;
    }
    return color;
  };

  return (
    <div
      key={group.userChapterId}
      className={`wrapper-item `}
      style={{
        marginLeft: group.level <= 1 ? "0px" : "48px",
        borderLeft: group.level <= 1 ? "0px" : "1px solid #dbe8ef",
      }}
    >
      <div
        className="main-row"
        // style={{
        //   borderTop: group.level === 1 ? "1px solid #dbe8ef" : "0px",
        // }}
      >
        {/* 拖曳功能 */}
        {group.hasDrag && (
          <div className="move-box">
            <LinkIconButton
              className={"icon-move"}
              imgName={"hambur-icon.svg"}
              text={""}
              onClick={() => {}}
            />
          </div>
        )}
        {/* 選核功能 */}
        <div
          className={`check-box ` + `${group.level <= 0 ? "" : "col-item"} `}
        >
          <label className="custom-checkbox">
            <input
              type="checkbox"
              checked={group.isNeedFilled}
              disabled={group.fillType === FillType.Necessary}
              onChange={(event) => {
                clickItem(groupsIndex, group, "checked");
              }}
            />
            <span>&nbsp;</span>
          </label>
        </div>
        {/* 章節編號 */}
        <div className="col-item code">{getCode()}</div>
        {/* 章節名稱 */}
        <div className="col-item chapter-title " title={group.title || ""}>
          {group.title}
        </div>
        {/* 章節分類 */}
        <div className={`col-item category ` + 
          `${getColor(group.fillType)} `}>
          {group.fillTypeDescription}
        </div>
        {/* 後面的收合功能 */}
        <div className="col-item extend">
          {/* 底下還有子節點才可以縮合*/}
          {group.children.length > 0 && (
            <LinkIconButton
              imgName={group.show ? "cut-icon.svg" : "add-icon.svg"}
              text={""}
              onClick={() => {
                clickItem(groupsIndex, group, "extend");
              }}
            />
          )}
        </div>
      </div>
      {group.show && <Container key={group.userChapterId} {...props} />}
    </div>
  );
};

export default Wrapper;
