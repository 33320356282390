import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./MailList.module.scss";
import { error2Alert, success2Alert } from "utils/otherToast";
import { ListRequestModel, PageActionTyep } from "models/baseModel";
import { CommonService } from "services/common/commonService";
import { usePermissionContext } from "context/PermissionProvider";
import { useMutation } from "@tanstack/react-query";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import NormalInput from "components/base/NormalInput/NormalInput";
import { EmailManageActionsPermissionCodes } from "models/auth/permissionModel";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import TablePagination from "components/base/TablePagination/TablePagination";
import { normalDate3 } from "utils/dateTimeFormat";
import SearchInput from "components/base/SearchInput/SearchInput";
import CorrectionRequestEmailManageService from "services/admin/correctionRequestEmailManageService";
import {
  CorrectionRequestEmailManageShowModel,
  EditCorrectionRequestEmailModel,
} from "models/admin/correctionRequestEmailManageModel";

interface MailListProps {}

const MailList: FC<MailListProps> = () => {
  // #region 變數
  const [list, setList] = useState<CorrectionRequestEmailManageShowModel[]>([]);
  const [orgList, setOrgList] = useState<
    CorrectionRequestEmailManageShowModel[]
  >([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkCount, setCheckCount] = useState(0);
  const [openCheck, setOpenCheck] = useState(false);
  const [request, setRequest] = useState<ListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
    sortType: null,
  });
  const [orderArray, setOrderArray] = useState<boolean[]>([true, true]);
  const sortImg = "/assets/images/buttonIcon/sort-icon.svg";
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  const infoData = useRef<EditCorrectionRequestEmailModel>({
    email: "",
    id: "",
  });
  /** 動作 */
  const [action, setAction] = useState<string>(PageActionTyep.Add);
  /** email檢查 */
  const [emailValid, setEmailValid] = useState<boolean>(true);

  /** 新增/編輯modal */
  const [infoModal, setInfoModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setInfoModal({
        ...infoModal,
        show: false,
      });
      setEmailValid(false);
      infoData.current.email = "";
    },
    handleConfirm: () => {},
    title: "新增信箱",
  });

  // #endregion

  // #region CRUD

  //取的清單
  const getCorrectionRequestEmailList = useMutation({
    mutationFn: (model: ListRequestModel) =>
      CorrectionRequestEmailManageService.getCorrectionRequestEmailList(model),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const data = res.data;
        setOrgList(CommonService.deepClone(data));
        setList(CommonService.deepClone(data));
        setTotalCount(res.count || 0);
      } else {
        console.log(res.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    getCorrectionRequestEmailList.mutate(request);
  }, []);

  // 新增
  const addCorrectionRequestEmail = useMutation({
    mutationFn: (model: EditCorrectionRequestEmailModel) =>
      CorrectionRequestEmailManageService.addCorrectionRequestEmail(model),
    onSuccess: (res) => {
      setInfoModal({
        ...infoModal,
        loading: false,
        show: false,
      });
      if (res.code === 200 && res.success) {
        getCorrectionRequestEmailList.mutate(request);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (error) => {
      error2Alert("新增失敗");
      setInfoModal({
        ...infoModal,
        loading: false,
      });
      console.log(error);
    },
  });

  // 刪除
  const deleteCorrectionRequestEmail = useMutation({
    mutationFn: (manageIds: string[]) =>
      CorrectionRequestEmailManageService.deleteCorrectionRequestEmail(
        manageIds
      ),
    onSuccess: (res) => {
      setConfirmModal({
        ...confirmModal,
        show: false,
        loading: false,
      });
      if (res.code === 200 && res.success) {
        getCorrectionRequestEmailList.mutate(request);
        success2Alert(res.message);
      } else {
        error2Alert("刪除失敗");
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  //刪除清單
  const handleRemove = (id: string | null) => {
    let ids: string[] = [];
    // null 批次刪除
    if (!id) {
      ids = list.filter((p) => p.checked).map((p) => p.id);
    } else {
      ids = [id!];
    }
    setConfirmModal({
      ...confirmModal,
      show: true,
      handleConfirm: () => {
        deleteCorrectionRequestEmail.mutate(ids);
      },
    });
  };

  // 編輯
  const editCorrectionRequestEmail = useMutation({
    mutationFn: (model: EditCorrectionRequestEmailModel) =>
      CorrectionRequestEmailManageService.editCorrectionRequestEmail(model),
    onSuccess: (res) => {
      setInfoModal({
        ...infoModal,
        loading: false,
        show: false,
      });
      if (res.code === 200 && res.success) {
        getCorrectionRequestEmailList.mutate(request);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (error) => {
      error2Alert("編輯失敗");
      setInfoModal({
        ...infoModal,
        loading: false,
      });
      console.log(error);
    },
  });

  // 編輯事件
  const handleSave = (item: CorrectionRequestEmailManageShowModel) => {
    let valid = emailValidFlow(item.email);
    if (valid) {
      editCorrectionRequestEmail.mutate(item);
    }
    setList(
      list.map((p) => {
        p.valid = true;
        if (p.id === item.id) {
          p.valid = valid;
        }
        return p;
      })
    );
  };
  /** 更改清單email */
  const handleChangeEmail = (
    item: CorrectionRequestEmailManageShowModel,
    newEmail: string
  ) => {
    setList(
      list.map((p) => {
        if (p.id === item.id) {
          p.email = newEmail;
        }
        return p;
      })
    );
  };

  // #endregion

  // #region 事件 & 確認事件
  // modal 物件(confirm)
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setConfirmModal({
        ...confirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認",
  });

  // 全選
  const handleAllCheckbox = () => {
    setCheckAll(!checkAll);
    if (!checkAll === true) {
      setCheckCount(list.length);
    } else {
      setCheckCount(0);
    }
    list.forEach((item) => {
      item.checked = !checkAll;
    });
    setList([...list]);
  };

  // 總數計算
  const countCheckbox = () => {
    const total = list.length;
    const checked = list.filter((x) => x.checked === true).length;
    setCheckCount(checked);
    if (total !== 0 && total === checked) {
      setCheckAll(true);
    } else if (checked !== 0 && total !== checked) {
      setCheckAll(false);
    } else {
      setCheckAll(false);
    }
  };

  const handleChkChange = (id: string | null) => {
    if (id) {
      setList(
        list.map((ck, index) => {
          if (ck.id === id) {
            ck.checked = !ck.checked;
          }
          return ck;
        })
      );
      countCheckbox();
    }
  };

  // 打開編輯toggle
  const handleEditToggle = (index: number | null, isEdit: boolean) => {
    let tmpList = [...list];
    if (index === null) {
      tmpList.forEach((t) => {
        t.isEdit = false;
      });
    } else {
      tmpList[index].isEdit = isEdit;
      tmpList[index].email = orgList[index].email;
      tmpList[index].valid = true;
    }
    setList([...tmpList]);
  };
  // 選取年度開關
  const handleOpenCheckToggle = () => {
    setOpenCheck(!openCheck);
    setCheckAll(false);
    setCheckCount(0);
    handleEditToggle(null, false);
    // 初始化check
    setList(
      list.map((ck, index) => {
        ck.checked = false;
        return ck;
      })
    );
  };

  // 查詢key
  const handleSearch = (key: string) => {
    const newObj = {
      ...request,
      searchKey: key,
      page: 1,
    };
    setRequest(newObj);
    getCorrectionRequestEmailList.mutate(newObj);
  };

  // 排序
  const handleSort = (key: string, index: number) => {
    let sortTyep = true;
    setOrderArray(
      orderArray.map((p, itemIndex) => {
        if (itemIndex === index) {
          p = !p;
          sortTyep = p;
        }
        return p;
      })
    );
    const newReq = {
      ...request,
      sortKey: key,
      sortType: sortTyep,
    };
    setRequest(newReq);
    getCorrectionRequestEmailList.mutate(newReq);
  };

  /** 打開資訊modal */
  const openInfoModal = (
    item: EditCorrectionRequestEmailModel,
    action: string
  ) => {
    setAction(action);

    infoData.current = {
      ...infoData.current,
      ...item,
    };

    let preTitle = action === PageActionTyep.Add ? "新增" : "編輯";
    setInfoModal({
      ...infoModal,
      show: true,
      title: `${preTitle}信箱`,
    });
  };

  /** 儲存modal資訊 */
  const save = () => {
    setEmailValid(true);
    if (emailValidFlow(infoData.current.email)) {
      setInfoModal({
        ...infoModal,
        loading: true,
      });

      if (action === PageActionTyep.Add) {
        addCorrectionRequestEmail.mutate(infoData.current);
      } else {
        editCorrectionRequestEmail.mutate(infoData.current);
      }
    } else {
      setEmailValid(false);
    }
  };

  /** email flow */
  const emailValidFlow = (email: string): boolean => {
    let pass = true;
    let msg = "";
    if (!email) {
      pass = false;
      msg = "請輸入Email";
    } else {
      if (!CommonService.emailValid(email)) {
        pass = false;
        msg = "Email 格式不正確";
      }
    }

    if (!pass) {
      error2Alert(msg);
    }
    return pass;
  };
  // #endregion

  return (
    <div className="no-nav-content-box">
      <div className={styles["mail-box"]}>
        {/*上方bar*/}
        <div className="title-box">
          <div className="title">更正信箱管理</div>
        </div>
        {(getCorrectionRequestEmailList.isPending ||
          addCorrectionRequestEmail.isPending ||
          deleteCorrectionRequestEmail.isPending ||
          editCorrectionRequestEmail.isPending) && <VisuallLoading />}
        <div className="tool-box mt-20-p">
          <div className="tool-box">
            {!openCheck ? (
              <div className="mr-1-m">
                <SearchInput
                  onClick={(e) => {
                    const newParam = {
                      ...request,
                      page: 1,
                      searchKey: e,
                    };
                    setRequest(newParam);
                    getCorrectionRequestEmailList.mutate(newParam);
                  }}
                  placeholder={"搜尋"}
                />
              </div>
            ) : (
              <div className="tool-box mt-10-p">
                <div className="sel-item">已選取{checkCount}項目</div>
                {checkCount > 0 && (
                  <>
                    {detectEveryActionPermission([
                      EmailManageActionsPermissionCodes.CorrectionEmailDelete,
                    ]) ? (
                      <div className="icon-button">
                        <LinkIconButton
                          imgName={`${
                            checkCount > 0
                              ? "trash-icon.svg"
                              : "trash-lock-icon.svg"
                          }`}
                          text="刪除"
                          className={`${checkCount > 0 ? "" : "lock"}`}
                          onClick={
                            checkCount > 0 ? () => handleRemove(null) : () => {}
                          }
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            )}
          </div>

          <div className="tool-box">
            <button
              className="secondary mr-1-m"
              onClick={handleOpenCheckToggle}
            >
              {!openCheck ? "多筆編輯" : "取消選取"}
            </button>
            {detectEveryActionPermission([
              EmailManageActionsPermissionCodes.CorrectionEmailCreate,
            ]) ? (
              <button
                className="default mr-1-m"
                onClick={() => {
                  openInfoModal(
                    {
                      id: "",
                      email: "",
                    },
                    PageActionTyep.Add
                  );
                }}
              >
                +新增
              </button>
            ) : null}
          </div>
        </div>

        {/** 主表 */}
        <div className="result-box scroll">
          <div className="table-box ">
            <table
              aria-label="查詢結果table"
              className="table-container sticky-table"
            >
              <thead>
                <tr>
                  {openCheck && (
                    <th style={{ width: "50px" }} scope="col">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          checked={checkAll}
                          onChange={() => handleAllCheckbox()}
                        />
                        <span>&nbsp;</span>
                      </label>
                    </th>
                  )}
                  <th scope="col">
                    <div className="sort">
                      信箱
                      <img
                        className={orderArray[0] ? "" : "desc"}
                        alt="email"
                        src={sortImg}
                        onClick={() => {
                          handleSort("email", 0);
                        }}
                      />
                    </div>
                  </th>
                  <th scope="col">
                    <div className="sort">
                      編輯日期
                      <img
                        className={orderArray[1] ? "" : "desc"}
                        alt="updateTime"
                        src={sortImg}
                        onClick={() => {
                          handleSort("updateTime", 1);
                        }}
                      />
                    </div>
                  </th>
                  <th scope="col" style={{ width: "40px" }}></th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 ? (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={3}>
                      查無資料
                    </td>
                  </tr>
                ) : (
                  list.map((p, i) => {
                    return (
                      <tr key={p.id}>
                        {/*開啟checkbox的td*/}
                        {openCheck && (
                          <td>
                            <label className="custom-checkbox">
                              <input
                                type="checkbox"
                                checked={p.checked}
                                onChange={() => handleChkChange(p.id)}
                              />
                              <span>&nbsp;</span>
                            </label>
                          </td>
                        )}
                        {/* 信箱*/}
                        {openCheck || p.isEdit !== true ? (
                          <td>{p.email}</td>
                        ) : (
                          <td>
                            <NormalInput
                              defaultValue={p.email}
                              valid={p.valid}
                              onChange={(e: string) => {
                                handleChangeEmail(p, e);
                              }}
                              placeholder={"信箱"}
                            />
                          </td>
                        )}
                        {/*編輯日期*/}
                        <td>
                          {p.updateTime ? normalDate3(p.updateTime) : "-"}
                        </td>
                        {!openCheck ? (
                          <td>
                            {p.isEdit === true ? (
                              /** 儲存與取消 */
                              <div className="d-flex">
                                {detectEveryActionPermission([
                                  EmailManageActionsPermissionCodes.CorrectionEmailUpdate,
                                ]) ? (
                                  <>
                                    <LinkIconButton
                                      imgName="check-icon.svg"
                                      text=""
                                      imgStyle={{
                                        width: "24px",
                                        height: "24px",
                                      }}
                                      onClick={() => handleSave(p)}
                                    />
                                    <LinkIconButton
                                      imgName="cross-icon.svg"
                                      text=""
                                      imgStyle={{
                                        width: "24px",
                                        height: "24px",
                                      }}
                                      onClick={() => handleEditToggle(i, false)}
                                    />
                                  </>
                                ) : null}
                              </div>
                            ) : (
                              <>
                                {detectEveryActionPermission([
                                  EmailManageActionsPermissionCodes.CorrectionEmailUpdate,
                                ]) ? (
                                  /* 開啟編輯*/
                                  <LinkIconButton
                                    imgName="right-arrow.svg"
                                    text=""
                                    onClick={() => handleEditToggle(i, true)}
                                  />
                                ) : null}
                              </>
                            )}
                          </td>
                        ) : (
                          <td>
                            {/** 刪除 */}
                            <div className="d-flex">
                              {detectEveryActionPermission([
                                EmailManageActionsPermissionCodes.CorrectionEmailDelete,
                              ]) ? (
                                <LinkIconButton
                                  imgName="trash-icon.svg"
                                  text=""
                                  className={"hover"}
                                  onClick={() => handleRemove(p.id)}
                                />
                              ) : null}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })
                )}
                {/* 確認刪除modal */}
                <ConfirmModal {...confirmModal}>
                  <div>是否刪除資料?</div>
                </ConfirmModal>
              </tbody>
            </table>
          </div>
        </div>
        {/* 分頁 */}
        <div className="pad-10 table-pagination">
          <TablePagination
            page={request.page}
            pageSize={request.pageSize}
            onChange={(e) => {
              const newReq = {
                ...request,
                pageSize: e.pageSize,
                page: e.page,
              };
              setRequest(newReq);
              getCorrectionRequestEmailList.mutate(newReq);
            }}
            total={totalCount}
          />
        </div>
      </div>
      {/* 新增/編輯modal */}
      <ConfirmModal {...infoModal} handleConfirm={save}>
        <div className="propaganda-info-box">
          <div>
            信箱<span className="star">*</span>
          </div>
          <div>
            <NormalInput
              type="text"
              placeholder="信箱"
              defaultValue={infoData.current.email}
              valid={emailValid}
              onChange={(e) => {
                infoData.current.email = e;
              }}
            />
          </div>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default MailList;
