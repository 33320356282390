import React, { FC } from "react";
import styles from "./MailManageRouter.module.scss";
import { usePermissionContext } from "context/PermissionProvider";
import {
  ActionsPermissionCodes,
  FeaturePermissionCodes,
  PermissionRouteObjectModel,
} from "models/auth/permissionModel";
import MailList from "pages/Admin/MailManage/MailList/MailList";
import { useRoutes } from "react-router-dom";

interface MailManageRouterProps {}

const MailManageRouter: FC<MailManageRouterProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionService } = usePermissionContext();
  const {
    permissionRouter,
    detectSomeFeatruePermission,
    detectSomeActionPermission,
  } = PermissionService;

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: <MailList />,
      featurePermission: FeaturePermissionCodes.EmailManage.CorrectionEmailManage,
      actionReadPermission: ActionsPermissionCodes.EmailManage.CorrectionEmailRead,
    },
  ];

  //* ---------------------------- state end ---------------------------- *//
  let elements = useRoutes(permissionRouter(routers));
  return (
    <>
      <div className={styles["mail-layout"]}>{elements}</div>
    </>
  );
};

export default MailManageRouter;
