import { ListRequestModel } from 'models/baseModel';
import { BoardOfDirectorsStatus } from 'models/application/sustainReportDeclare/sustainReportDeclareModel';
import { ReportProduceStatus } from 'models/application/sustainReportDeclare/sustainReportProduceModel';
import { DeclareStatus } from 'models/application/indicators/indicatorModel';
// #region 異動申請管理 TransactionRequest

export interface DeclareDataRequestModel extends ListRequestModel {
	declareYear: number | undefined;
	requestType: number | undefined;
	status: number | undefined;
	companyInfo: string | undefined;
}

export enum RequestTypeEnum {
	//- 永續報告書
	SustainReport,
	//- 溫室氣體排放及減量
	GhgEmissionAndReduction,
	//- ESG資訊揭露
	ESGDisclosure,
}

export enum CorrectionStatusEnum {
	//- 已申請未開通
	AppliedUnactivated,
	//- 已開通未申報
	ActivatedUndeclared,
	//- 已重新申報
	Declared,
}

export enum Multilingual {
	//- 中文版
	Chinese,
	//- 英文版
	English,
}

export interface DeclareDataMangeModel {
	id: string;
	declareYear: number;
	requestType: RequestTypeEnum;
	multilingual?: Multilingual;
	companyCode: string;
	companyName: string;
	applyDate: string;
	applyReason: string;
	permissionActivationTime?: string;
	editDeclareDate?: string;
	status: CorrectionStatusEnum;
}

// #endregion 異動申請管理 TransactionRequest

// #region ESG資訊揭露 EsgDisclosure

export interface EsgDisclosureDataRequestModel extends ListRequestModel {
	declareYear: number | undefined;
	status: number | undefined;
	searchInfo: string | undefined;
}

export interface EsgDisclosureDataModel {
	id: string;
	declareYear: number;
	editDeclareDate?: string;
	companyCode: string;
	companyName: string;
	status: DeclareStatusEnum;
	statusName: string;
	lastEditDate: string;
	firstDeclareDate: string;
	canEdit: boolean;
}

export enum DeclareStatusEnum {
	//- 未申報
	UnDeclared,
	//- 申報中
	Declaring,
	//- 已申報
	Declared,
	//- 申請更正
	ApplyForCorrection,
	//- 更正中
	Correcting,
}

// #endregion ESG資訊揭露 EsgDisclosure

// #region 溫室氣體排放及減量資訊 GhgEmissionsReduction

// #endregion 溫室氣體排放及減量資訊 GhgEmissionsReduction

// #region 永續報告書清單 SustainabilityReport

/** 取得申報資料管理-永續報告書清單 */

export interface SustainReportDeclareDataModel {
	declareYear: number;
	companyCode: string;
	companyName: string;
	declareId: string;
	twFirstDeclareDate: string;
	twLastEditDate: string;
	twReportRevisionUploadTime: string;
	twEditDeclareDate: string;
	twStatus: DeclareStatusEnum;
	boardOfDirectorsStatus: BoardOfDirectorsStatus;
	mentionedDate: string;
	enFirstDeclareDate: string;
	enLastEditDate: string;
	enReportRevisionUploadTime: string;
	enEditDeclareDate: string;
	enStatus: DeclareStatusEnum;
}

// #endregion 永續報告書清單 SustainabilityReport

// #region 申報資料設定 DeclareSetting

export interface DeclareSettingDataRequestModel {
	requestType: number;
	declareYear?: number;
}

export interface DeclareSettingDataModel {
	requestType: number;
	declareYear: number | undefined;
	declareStartDate: string | undefined;
	declareEndDate: string | undefined;
	reportLimitSize: number;
}

// #endregion 申報資料設定 DeclareSetting

// #region 匯出檔案 ExportDocument

export interface ExportCsvModel {
	declareYear: number;
	fileName: string;
	fileSize: number;
	marketType: number;
	fileType: number;
	exportDate: string;
}

/** 匯出檔案相關搜尋 */
export interface DeclareYearRequestModel extends ListRequestModel {
	declareYear: number | null;
}

export interface FileRequestDownloadModel {
	fileMarketType: number;
	fileType: number;
	declareYear: number;
	exportDate: string;
}

// #endregion 匯出檔案 ExportDocument


//#region 報告書產製
/** 報告書產製Model */
export interface ReportProduceSearchModel extends ListRequestModel {
	declareYear?: number;
	status?: ReportProduceStatus;
	searchInfo: string | null;
}

/** 申報管理資料-報告書產製清單 */
export interface AllCompanyReportProduceShowModel extends ReportProduceShowModel {
	companyCode: string;
	companyName: string;
}

export interface ReportProduceShowModel {
	id: string;
	declareYear: number;
	lastEditDate: Date | null;
	status: ReportProduceStatus;
	statusName: string;
	canEdit: boolean;
	isFinished: boolean;
}
//#endregion
