import { ListRequestModel, MultiOptionModel } from 'models/baseModel';
import { v4 as uuid } from 'uuid';

export interface SustainRreportManuscriptManageListModel {
  id: string;
  year: number | null;
  lastEditDate: Date;
  /** 前端選核使用 */
  checked?: boolean;
  /** 前端使用是否編輯 */
  isEdit?: boolean;
}

export interface SustainRreportManuscriptModel {
  year: number;
}

export interface SustainRreportManuscriptManageDataModel {
  id: string;
  chineseManuscript: string | null;
  englishManuscript: string | null;
  year: number;
}

/** 搜尋參數 */
export interface ManuscriptRequestModel extends ListRequestModel { }

/** ManuscriptEditionEnum */
export enum ManuscriptEditionEnum {
  zh = 'zh',
  en = 'en',
}

export interface DisclosureSettingModel {
  industryId: string;
  name: string;
  reportIndicator: Array<DisclosureSettingIndicatorModel>;
}

export interface DisclosureSettingIndicatorModel {
  id: string;
  over2BType: number | null; //0,1,2
  under2BType: number | null; //0,1,2
  title: string | null;
  isEdit: boolean | null;
}

/** SustainReportDefaultTreeNodeModel */
export class SustainReportDefaultTreeNodeModel {
  manageId: string = '';
  id: string = '';
  nodeId: string = '';
  title: string = '';
  content: string | null = null;
  remark: string = '';
  parentId: string | null = null;
  sortIndex: number | null = null;
  chapterString: string = '';
  // recordId: string | undefined;
  level: number | null = null;
  children: SustainReportDefaultTreeNodeModel[] = [];
  //- web use
  uniqueKey: string = uuid();
  isCollapsed: boolean = false;
  webChapterIndex: string = '';
  path: number[] = [];
  checkValid: boolean = true;
}

/** SustainReportDefaultTreeNodeRequestModel */
export interface SustainReportDefaultTreeNodeRequestModel {
  manageId: string;
  SustainReportDefaultTreeNodes: SustainReportDefaultTreeNodeModel[];
}

/** SustainReportDefaultFlatTreeNodeModel */
export class SustainReportDefaultFlatTreeNodeModel {
  id: string = '';
  title: string = '';
  sortIndex: number = 0;
  chapterIndex: number | null = null;
  chapterString: string = '';
  level: number = 0;
  defaultDataId: string = '';
  defaultId: string = '';
  //- web use
  uniqueKey: string = uuid();
  webChapterIndex: string = '';
  webChapterIndexTitle: string = '';
  parentId: string = '';
}

/** SustainReportDefaultLevelTreeNodeModel */
export class SustainReportDefaultLevelTreeNodeModel extends SustainReportDefaultFlatTreeNodeModel {
  children: SustainReportDefaultLevelTreeNodeModel[] = [];
}

/** SustainReportManuscriptDefaultDataModel */
export class SustainReportManuscriptDefaultDataModel {
  defaultId: string = '';
  dataId: string = '';
  title: string = '';
  griStandards: number[] = [];
  manuscript: string = '';
  griIndex: string = '';
  referenceExample: string = '';
  tableList: SustainReportManuscriptDefaultTableModel[] = [];
  tableIdList: string[] = [];
  //- web use
  griOptionsVal: MultiOptionModel[] = [];
  titleCheckValid: boolean = true;
}

/** SustainReportManuscriptDefaultTableModel */
export class SustainReportManuscriptDefaultTableModel {
  tableId: string = '';
  tableName: string = '';
  columnSize: number = 0;
  rowSize: number = 0;
  comment: string = '';
  cells: SustainReportManuscriptDefaultCellModel[] = [];
  //- web use
  tableNameCheckValid: boolean = true;
}

/** SustainReportManuscriptDefaultCellModel */

export class SustainReportManuscriptDefaultCellModel {
  tableId: string = '';
  cellId: string = '';
  rowIndex: number = 0;
  columnIndex: number = 0;
  cellValue: string = '';
}

/** SustainReportGriStandardModel */
export class SustainReportGriStandardModel {
  griSno: number = 0;
  griStandard: string = '';
}

export interface SerchDateModel {
  SerchStartDate: string | undefined;
  SerchEndDate: string | undefined;
}