import { DeclareStatus } from "models/application/indicators/indicatorModel";
import { TemplateSetType } from "models/application/sustainReportDeclare/sustainReportDeclareModel";

export interface SustainReportUserTreeNodeRequestModel {
    produceId: string;
    sustainReportUserTreeNode: SustainReportUserTreeNode[] | null;
}

export interface SustainReportUserTreeNode {
    produceId: string;
    userChapterId: string;
    title: string | null;
    parentId: string | null;
    sortIndex: number;
    chapterString: string | null;
    isNeedFilled: boolean;
    fillType: FillType;
    fillTypeDescription: string | null;
    level: number;
    isFinished: boolean;
    /** 父節點ID，NULL表示根節點 */
    children: SustainReportUserTreeNode[];

    /** 以下前端使用 */
    /** 是否有拖曳 */
    hasDrag?: boolean;
    /** 是否展開 */
    show?: boolean;
}

export interface SustainReportManuscriptUserDataModel {
    userChapterId: string;
    /** GUID 主鍵 */
    userChapterDataId: string;
    /** 章節名稱 */
    title: string | null;
    /** GRI準則 */
    griStandards: number[] | null;
    /** 底稿 */
    content: string | null;
    /** GRI指標 */
    griIndex: string | null;
    /** 參考範例 */
    referenceExample: string | null;
    tableList: SustainReportManuscriptUserTableModel[] | null;
    parameterList: SustainReportParaValueModel[] | null;
    /** 填寫類型 */
    fillType: FillType;
    /** 填寫類型enum 名稱 */
    fillTypeDescription: string;
    /** 是否欄位鎖定(目前for7-3 附錄表) */
    isColumnLocked: boolean;
}

export interface SustainReportManuscriptUserTableModel {
    tableId: string;
    tableName: string;
    columnSize: number;
    rowSize: number;
    cells: SustainReportManuscriptUserCellModel[];
    comment: string;
}

export interface SustainReportManuscriptUserCellModel {
    tableId: string;
    cellId: string;
    rowIndex: number;
    columnIndex: number;
    cellValue: string | null;
}

/** 報告書產製清單model */
export interface SustainReportProduceShowModel {
    id: string;
    /** 指標市場別Id */
    indicatorMarketId: string;
    /** 年度管理Id */
    manageId: string;
    /** 公司代碼 */
    companyCode: string;
    produceYear: number;
    /** 更正產製日期 */
    modifiedProduceTime: Date | null;
    /** 產製狀態 */
    produceStatus: ReportProduceStatus;
    /** 產製步驟 */
    produceStatusName: string;
}

export interface SustainReportProduceStatusModel {
    produceId: string;
    action: ReportProduceAction;
}

/** 樹狀列表 */
export interface SustainReportUserFlatTreeNode {
    /**  */
    userChapterId: string;
    /** GUID 主鍵 */
    userChapterDataId: string | null;
    /** 標題 */
    title: string | null;
    /** 父節點ID，NULL表示根節點 */
    parentId: string | null;
    sortIndex: number;
    level: number;
    /** 是否填寫完成 */
    isFinished: boolean;
    /** 章節串接code */
    chapterString: string | null;
    children: SustainReportUserFlatTreeNode[];
}

/** 狀態資訊資料 */
export interface SustainReportProduceInfoModel {
    produceId: string;
    year: number;
    /** 產製狀態 */
    produceStatus: ReportProduceStatus;
    produceStatusName: string;
    /** 步驟steps */
    stepStatus: ReportProduceAction;
}

/** 參數替代 */
export interface SustainReportParaValueModel {
    parameterName: string;
    value: string;
}

/** 前端搜尋model */
export interface SustainReportUserDataRequestModel {
    userChapterId: string;
    userChapterDataId: string | null;
    isPreview: boolean | null;
    /** 年度 */
    year?: number;
    /** 哪種類型 */
    fillType?: FillType;
}

/** 步驟itemModel */
export interface StepItemModel {
    key: string;
    sno: number;
    name: string;
    status: ReportProduceAction;
};

/** 預覽model */
export interface SustainReportUserPreviewModel {
    content: string | null;
    title: string | null;
    sortIndex: number;
    level: number;
    chapterString: string | null;
    tables: SustainReportManuscriptUserTableModel[] | null;
}

/** 報告書套版類型模組 */
export interface ReportTemplateModel {
    /** 年度 */
    year: number;
    /** 套版類型 */
    templateSetType: TemplateSetType;
}

/** 檔案要求Model */
export interface FileRequestModel {
    year: number;
    isImport: boolean;
}

/** 申報狀態 */
export enum ReportProduceStatus {
    /** 未產製 */
    NotStarted,
    /** 產製中 */
    InProgress,
    /** 產製完成 */
    Completed
}

/** 產製步驟 */
export enum ReportProduceAction {
    /** 開始產製 */
    StartProduce,
    /** 選擇章節 */
    SelectChapter,
    /** 製作報告書 */
    ReportGenerate,
    /** 選擇樣板 */
    SelectStyle,
    /** 匯出永續報告書 */
    ExportReport
}


export enum FillType {
    /**必要 */
    Necessary,
    /** 建議 */
    Suggestion,
    /** 其他 */
    Other
}

/** 報告書產製檔案清單 */
export interface ReportProduceFileModel {
    id: string;
    fileName: string;
    fileSize: number;
}