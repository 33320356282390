import React, { FC, useEffect, useState } from "react";
import styles from "./ExportDocument.module.scss";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { useMutation } from "@tanstack/react-query";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert, successAlert } from "utils/otherToast";
import {
  EduPropagandaResourceFileModel,
  EduPropagandaResourceListRequestModel,
} from "models/application/educationPropaganda/eduPropagandaResourceModel";
import EduPropagandaResourceService from "services/application/EducationPropaganda/eduPropagandaResource";
import { CommonService } from "services/common/commonService";
import FloatCalculateService from "services/common/floatCalculateService";

//* ---------------------------- React start ---------------------------- *//
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import DeclarationService from "services/admin/declarationService";
import {
  DeclareYearRequestModel,
  ExportCsvModel,
  FileRequestDownloadModel,
} from "models/admin/declarationModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import { normalDate } from "utils/dateTimeFormat";
import TablePagination from "components/base/TablePagination/TablePagination";
import { ListRequestModel, OptionModel } from "models/baseModel";
import Dropdown from "components/base/Dropdown/Dropdown";
//* ---------------------------- local end ---------------------------- *//

interface ExportDocumentProps {}

const ExportDocument: FC<ExportDocumentProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const [fileList, setFileList] = useState<ExportCsvModel[]>([]);
  /** 年度清單 */
  const [declareYearOptions, setDeclareYearOptions] = useState<OptionModel[]>(
    []
  );

  /** 搜尋清單 */
  const [param, setParam] = useState<DeclareYearRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
    declareYear: null,
  });
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getExportCsvList
  const {
    mutate: getExportCsvListMutate,
    isPending: getExportCsvListIsPending,
  } = useMutation({
    mutationFn: (model: DeclareYearRequestModel) =>
      DeclarationService.getExportCsvList(model),
    onSuccess: (res) => {
      console.log("getExportCsvList", res);
      if (res.code === 200 && res.success) {
        setFileList([...res.data]);
      } else {
        setFileList([]);
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("getExportCsvList", err);
      setFileList([]);
    },
  });

  //- getYearList
  const { mutate: getYearListMutate, isPending: getYearListIsPending } =
    useMutation({
      mutationFn: () => DeclarationService.getYearList(),
      onSuccess: (res) => {
        console.log("getYearList", res);
        if (res.code === 200 && res.success) {
          //- 過濾res.data相同的年度
          const declareYearOptionsList = res.data
            .filter(
              (e: number, i: number, self: number[]) => self.indexOf(e) === i
            )
            .map((e: number) => ({
              id: e.toString(),
              text: `${e.toString()} 年度`,
              enumKey: e,
            })) as OptionModel[];
          setDeclareYearOptions([
            {
              id: "null",
              text: "全部",
              enumKey: null,
            },
            ...declareYearOptionsList,
          ]);
        }
      },
      onError: (err) => {
        console.log("getYearList", err);
        error2Alert("執行失敗");
      },
    });

  //- getExportCsvFile
  const {
    mutate: getExportCsvFileMutate,
    isPending: getExportCsvFileIsPending,
  } = useMutation({
    mutationFn: (request:FileRequestDownloadModel) =>
      DeclarationService.getExportCsvFile(request),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert("下載成功");
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  // const donwload = (filename: string) => {
  //   getExportCsvFileMutate(filename);
  // };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- useEffect init
  useEffect(() => {
    getYearListMutate();
    getExportCsvListMutate(param);
  }, []);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <div
      className={`${styles["export-file-box"]} export-file-table-scroll scroll`}
    >
      {(getExportCsvListIsPending || getYearListIsPending ||
        getExportCsvFileIsPending
      ) && (
        <VisuallLoading />
      )}
      <ScrollToTopButton
        targetClassName={"export-file-table-scroll"}
        bottom={90}
        right={35}
      />
      {/* 搜尋框 */}
      <div className={`${styles["operation-header"]}`}>
        <div className={`left`}>
          <div className={`select-item`}>
            <Dropdown
              placeholder={"年度"}
              defaultId={
                param.declareYear === null
                  ? "null"
                  : param.declareYear?.toString()
              }
              options={declareYearOptions}
              isFloatTitle={false}
              onChange={(e) => {
                let newParam = {
                  ...param,
                  declareYear:
                    declareYearOptions.find((o) => o.id === e)?.enumKey ?? null,
                };
                setParam(newParam);
                getExportCsvListMutate(newParam);
              }}
            />
          </div>
        </div>
      </div>
      {/* 資料集 */}
      <div className={"data-box scroll"}>
        <table aria-label="table" className={`table-container sticky-table`}>
          <thead>
            <tr>
              <th scope="col">年度</th>
              <th scope="col">檔案名稱</th>
              <th scope="col">匯出日期</th>
              <th scope="col">檔案下載</th>
            </tr>
          </thead>
          <tbody>
            {fileList?.length === 0 ? (
              <tr>
                <td colSpan={4}>查無資料</td>
              </tr>
            ) : (
              fileList?.map((file, index) => (
                <tr key={index}>
                  <td>{file.declareYear}</td>
                  <td>{file.fileName}</td>
                  <td>{file.exportDate}</td>
                  <td className="d-flex justify-content-center">
                    <div className="btn-box">
                      <button
                        className={"secondary"}
                        onClick={() => {
                          getExportCsvFileMutate({
                            fileMarketType: file.marketType,
                            fileType: file.fileType,
                            declareYear: file.declareYear,
                            exportDate: file.exportDate
                          });
                        }}
                      >
                        下載
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="table-pagination mb-20-p">
        <TablePagination
          page={param.page}
          pageSize={param.pageSize}
          onChange={(e) => {
            const tmpReq = {
              ...param,
              ...e,
            };
            setParam(tmpReq);
          }}
          total={fileList?.length}
        />
      </div>
    </div>
  );
};

export default ExportDocument;
