import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "./NormalTextarea.module.scss";
import _ from "lodash";
interface NormalTextareaProps {
  id?: string;
  placeholder: string;
  unit?: string | null;
  defaultValue?: string;
  //value?: string | null;
  onChange: (key: any) => void;
  disabled?: boolean | null;
  valid?: boolean;
  /** 浮動title */
  isFloatTitle?: boolean;
  /** 字數上限 */
  maxLength?: number;
  /** 延遲輸入時間 */
  debounceTime?: number;
  rows?: number;
}

const NormalTextarea: FC<NormalTextareaProps> = ({
  valid = true,
  debounceTime = 0,
  ...props
}) => {
  const [value, setValue] = useState<string | undefined>("");
  // 延遲輸出
  const debounce = useCallback(
    _.debounce((_searchVal) => {
      props.onChange(_searchVal);
    }, debounceTime),
    []
  );
  const handleChangeKey = (msg: any) => {
    setValue(msg);
    debounce(msg);
  };
  useEffect(() => {
    if (props.defaultValue === null) {
      setValue("");
    } else {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <div
      className={
        `${styles["normal-textarea-box"]} ` +
        `${valid ? "" : "invalid"} ` +
        `${props.disabled ? styles.disabled : ""} ` 
      }
    >
      <textarea
        id={props.id ? `textarea_${props.id}` : ``}
        maxLength={props.maxLength}
        className={`text-input scroll`}
        value={value || ""}
        disabled={props.disabled || false}
        placeholder={props.placeholder}
        onChange={($event) => handleChangeKey($event.target.value)}
        rows={props.rows || 2}
      ></textarea>

      {/* 浮動title */}
      {props.isFloatTitle && (
        <div className="placeholder-item">{props.placeholder}</div>
      )}
      {value && !props.disabled && (
        <div className="clear-btn" onClick={() => handleChangeKey("")}>
          <img alt="" src="/assets/images/buttonIcon/input-clear-icon.svg" />
        </div>
      )}
      <div className="unit">{props.unit}</div>
    </div>
  );
};

export default NormalTextarea;
