import { useEffect } from 'react';

declare global {
  interface Window {
    dataLayer: any[];
    gtag?: {
      (command: 'js', date: Date): void;
      (command: 'config', targetId: string, config?: Record<string, any>): void;
      (
        command: 'event',
        eventName: string,
        eventParams?: Record<string, any>
      ): void;
    };
  }
}

const envParameter: { hosts: string[]; gtm?: string; gtag?: string }[] = [
  //- chase test (gtm -> chase)
  {
    hosts: ['localhost', 'twse.chase.com.tw'],
    gtm: 'GTM-NDBLVKL2',
  },
  //- twse test (gtm -> chase, gtag -> twse)
  {
    hosts: ['esggenplustest.twse.com.tw'],
    // gtm: 'GTM-KQFFCLB8',
    gtag: 'G-27JWF9NEGD',
  },
  //- twse prod (gtm -> chase, gtag -> twse)
  {
    hosts: ['esggenplus.twse.com.tw'],
    // gtm: 'GTM-W4M5FDLL',
    gtag: 'G-HLX3BQ6NK2',
  },
];

const host = window.location.hostname;
const currentEnv = envParameter.find((env) => env.hosts.includes(host));
const gtmId = currentEnv?.gtm;
const gtagId = currentEnv?.gtag;

export const GTM = () => {
  useEffect(() => {
    if (window.dataLayer || !gtmId) return;

    const initGTM = () => {
      window.dataLayer = [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      const script = document.createElement('script');
      script.async = true;
      script.setAttribute('nonce', 'twse_gtm');
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

      script.onerror = () => {
        console.error('GTM loading failed');
      };

      document.head.insertBefore(script, document.head.firstChild);
    };

    if (document.readyState === 'complete') {
      initGTM();
    } else {
      window.addEventListener('load', initGTM);
    }

    return () => {
      window.removeEventListener('load', initGTM);
    };
  }, []);

  return null;
};

export const Gtag = () => {
  useEffect(() => {
    if (window.gtag || !gtagId) return;

    const initGtag = () => {
      const gtagScript = document.createElement('script');
      gtagScript.async = true;
      gtagScript.setAttribute('nonce', 'twse_gtag');
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;

      gtagScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(...args: any[]) {
          window.dataLayer.push(arguments);
        }
        (window as any).gtag = gtag;

        if (window.gtag) {
          window.gtag('js', new Date());
          window.gtag('config', gtagId, {
            cookie_flags: 'max-age=7200;secure;samesite=none',
          });
        }
      };

      document.head.insertBefore(gtagScript, document.head.firstChild);
    };

    if (document.readyState === 'complete') {
      initGtag();
    } else {
      window.addEventListener('load', initGtag);
    }

    return () => {
      window.removeEventListener('load', initGtag);
    };
  }, []);

  return null;
};

export const submitGAEvent = (
  eventName: string,
  submitEventObject?: Record<string, any>
) => {
  if (gtmId && window.dataLayer) {
    try {
      window.dataLayer.push({
        event: eventName,
        ...submitEventObject,
        timestamp: new Date().getTime(),
      });
    } catch (error) {
      console.error('GTM event push failed:', error);
    }
  }

  if (gtagId && typeof window.gtag === 'function') {
    try {
      window.gtag('event', eventName, {
        ...submitEventObject,
        send_to: gtagId,
      });
    } catch (error) {
      console.error('GA4 event push failed:', error);
    }
  }
};
